import styled from "@emotion/styled";
import {
  Breakpoints,
  Colors,
  Fonts,
  Transitions,
} from "../../assets/variables/variables";

export const ContactHeroContainer = styled.div`
  position: relative;
`;

export const Content = styled.div`
  padding-top: 70px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 365px;

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    padding-top: 50px;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    padding-top: 30px;
  }
`;

export const Footer = styled.div`
  display: inline-block;
  position: relative;
  min-height: 400px;
  width: 100%;

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    min-height: 300px;
  }
`;

export const ContactForm = styled.form`
  position: absolute;
  right: 0;
  width: 100%;
  padding: 50px 70px;
  border-radius: 12px;
  z-index: 10;
  background-color: #fff;
  box-shadow: 1px 1px 16px 1px #00000054;

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    padding: 50px 50px 30px 50px;
    position: relative;
    margin-bottom: 30px;
  }
`;

export const InputLayout = styled.div`
  width: 100%;
  padding-bottom: 50px;

  input,
  textarea {
    font-family: ${Fonts.$light};
    margin-right: 10px;
    width: calc(50% - 10px);
    border: none;
    padding: 10px 5px;
    outline: none;
    font-weight: bold;
    border-bottom: 2px solid ${Colors.$primarySuperLightColor};
    color: ${Colors.$primaryDarkColor};

    &::placeholder {
      color: ${Colors.$primaryMediumColor};
      opacity: 1;
    }

    &::-ms-input-placeholder {
      color: ${Colors.$primaryMediumColor};
    }

    &:last-of-type {
      margin: 0;
      margin-right: 10px;
    }
  }

  textarea {
    width: 100%;
    resize: none;
    height: 15ch;
  }

  button {
    padding: 10px 20px;
    background-color: ${Colors.$primarySuperLightColor};
    border-radius: 6px;
    box-shadow: 1px 1px 5px 1px #00000054;
    outline: none;
    border: none;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    letter-spacing: 2px;
    transition: 0.3s ${Transitions.$superSmooth};

    &:hover {
      color: ${Colors.$primaryMediumColor};
      box-shadow: 1px 1px 5px 3px #00000054;
    }
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  p {
    font-family: "Raleway-Bold";
    &.success {
      color: ${Colors.$colorSuccess};
    }

    &.fail {
      color: ${Colors.$colorError};
    }
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    padding-bottom: 30px;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    padding-bottom: 20px;

    input {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;

export const SocialMedias = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 30px);
  position: relative;
  z-index: 3;
  padding: 30px 50px;
  background-color: ${Colors.$colorGreyLight};
  box-shadow: 1px 1px 16px 1px #00000054;
  border-radius: 6px;
  margin: 70px 0;

  p {
    padding-bottom: 20px;
  }

  &:nth-of-type(2) {
    display: none;

    a {
      position: relative;
      display: block;

      svg {
        position: relative;
        width: 100px;
        height: 100px;
      }
    }
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    width: calc(50% - 8px);
    flex: 0 0 calc(50% - 8px);
    margin: 50px 0;

    &:first-of-type {
      margin-right: 16px;
    }

    &:nth-of-type(2) {
      display: flex;
    }
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    padding: 30px 30px;
    margin: 30px 0;

    p {
      text-align: center;
    }
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    padding: 15px;
    margin: 30px 0;
  }
`;

export const SocialMedia = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  outline: none;
  margin-bottom: 15px;
  width: fit-content;
  padding: 3px 0;

  img {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    position: relative;
  }

  &:before {
    content: "";
    position: absolute;
    right: -5px;
    bottom: 0;
    height: 100%;
    width: 0;
    opacity: 0;
    background-color: ${Colors.$colorGreenLight};
    transition: 0.4s ${Transitions.$superSmooth};
  }

  &:hover {
    &:before {
      opacity: 1;
      width: calc(100% + 10px);
    }
  }
`;
