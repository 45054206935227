import React, { useState } from "react";
import { Flex } from "../../layouts";
import { Card, CardContent, CardImageHeader, Seperator } from "./layouts";

const OurApproach = (data) => {
  const [approachData, setOurApproac] = useState(null);

  useState(() => {
    if (data) {
      setOurApproac(data.fields.content);
    }
  }, []);

  if (!approachData) return;

  return (
    <Flex className="flex-column align-center mb-70 mt-70 mb-lg-50 mt-lg-50 mb-md-10 mb-sm-30 mt-sm-30">
      <Flex className="w-full align-center flex-wrap-lg">
        {approachData &&
          approachData.map((card, index) => {
            const {
              icon = null,
              title = "Title",
              description = "",
            } = card.fields;

            const paragraphs = description.split("\n");
            const imageUrl = icon && icon.fields.file.url;

            return (
              <React.Fragment key={index}>
                <Card>
                  <CardImageHeader>
                    <img
                      src={imageUrl}
                      className="p-absolute-fixed object-cover"
                      alt="Approach pic"
                    />
                  </CardImageHeader>
                  <CardContent>
                    <h4>{title}</h4>
                    {paragraphs.map((paragraph, index) => (
                      <p key={index}>{paragraph}</p>
                    ))}
                  </CardContent>
                </Card>
                {index + 1 !== approachData.length && (
                  <Seperator className="seperator" />
                )}
              </React.Fragment>
            );
          })}
      </Flex>
    </Flex>
  );
};

export default OurApproach;
