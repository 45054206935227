import React, { useState } from "react";
import { Container } from "../../layouts";
import {
  CategoriesContainer,
  CategoriesTitle,
  CategoryCard,
  CategoryCardImage,
  CategoryCardTtile,
} from "./layouts";
import Image from "../image";
import { GeneralIcon } from "../../layouts/layouts";

const OurCategories = (data) => {
  const [categoriesData, setCategoriesData] = useState(null);

  useState(() => {
    if (data) {
      setCategoriesData(data.fields);
    }
  }, []);

  if (!categoriesData) return;

  return (
    <CategoriesContainer>
      <Container className="d-flex flex-wrap justify-lg-center">
        {categoriesData.title && (
          <CategoriesTitle>
            {categoriesData.title}
            <GeneralIcon />
          </CategoriesTitle>
        )}
        {categoriesData &&
          categoriesData.content.map((card, index) => {
            const { title = "Cat Title", icon = null, slug } = card.fields;
            
            if (slug === "dollap") {
              return (
                <CategoryCard key={index}>
                  <CategoryCardImage>
                    <Image source={icon.fields.file.url} size="verticalHuge" />
                  </CategoryCardImage>
                  <CategoryCardTtile>{title}</CategoryCardTtile>
                </CategoryCard>
              );
            } else {
              return (
                <CategoryCard to={`/products?categories=${slug}`} key={index}>
                  <CategoryCardImage>
                    <Image source={icon.fields.file.url} size="verticalHuge" />
                  </CategoryCardImage>
                  <CategoryCardTtile>{title}</CategoryCardTtile>
                </CategoryCard>
              );
            }
          })}
      </Container>
    </CategoriesContainer>
  );
};

export default OurCategories;
