import styled from "@emotion/styled";
import { Colors, Transitions } from "../../assets/variables/variables";

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 5px;
`;

export const CheckboxLabel = styled.label`
  width: 100%;
  padding-right: 20px;
  cursor: pointer;
  transition: 0.1s ${Transitions.$linearSmooth};

  input[type="checkbox"] {
    display: none;
  }

  span::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 17px;
    height: 17px;
    border: 2px solid #dfdfe0;
    border-radius: 4px;
    border-width: 2px;
  }

  span::after {
    content: "";
    display: none;
    width: 5px;
    height: 9px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 3px;
    right: 7px;
    border-color: #fff;
  }

  input[type="checkbox"]:checked + span::after {
    display: block;
  }

  input[type="checkbox"]:checked + span::before {
    background-color: ${Colors.$primaryColor};
    border-color: ${Colors.$primaryColor};
  }

  p {
    user-select: none;
  }

  &:hover {
    padding-left: 3px;
  }
`;

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  right: 0;
  width: 1px;
  height: 1px;
  margin: 0;
`;
