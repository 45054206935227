import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Icon from "../../assets/icons/icon";
import { BigTitle, Paragraph, SubTitle, Tag } from "../../layouts/layouts";
import { Container, Flex } from "../../layouts";
import { Colors } from "../../assets/variables/variables";
import Image from "../image";
import {
  ContactForm,
  ContactHeroContainer,
  Content,
  Footer,
  InputLayout,
  SocialMedia,
  SocialMedias,
} from "./layouts";

const ContactHero = ({ data }) => {
  const {
    title = "",
    location = null,
    description = "",
    socialMedias = null,
    socialContact = null,
    contactFormTitle = "",
    socialMediaBoxTitle = "",
    socialMediaLocationBoxTitle = "",
  } = data;
  const formRef = useRef();

  const [buttonStatus, setButtonStatus] = useState(false);

  const [alertInfo, setAlertInfo] = useState({
    message: "",
    type: "",
  });

  const toggleAlert = (message, type) => {
    if (type === "fail") {
      setAlertInfo({ message, type });

      setTimeout(() => {
        setButtonStatus(false);

        setAlertInfo({
          message: "",
          type: "",
        });
      }, 3500);
    } else {
      setButtonStatus("disabled");
      setAlertInfo({ message, type });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonStatus("loading");

    const name = e.target.name.value;
    const surname = e.target.surname.value;
    const email = e.target.email.value;
    const number = e.target.number.value;
    const subject = e.target.subject.value;

    const templateParams = {
      to_name: "BS Trade",
      from_name: name,
      from_surname: surname,
      from_email: email,
      from_number: number,
      from_subject: subject,
    };

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then((response) => {
        if (response && response.status === 204) {
          toggleAlert("Received a 204 response, handle accordingly", "fail");
          return;
        }

        toggleAlert("Mesazhi juaj është dërguar!", "success");
        formRef.current.reset();
      })
      .catch((error) => {
        toggleAlert(
          "Ka ndodhur një gabim, ju lutem provoni përsëri! Error:" +
            error.status,
          "fail"
        );
      })
      .finally(() => {
        setButtonStatus("idle");
      });
  };

  return (
    <ContactHeroContainer>
      <Content>
        <Icon icon="contactContent" />
        <Container className="p-relative d-flex flex-wrap align-start">
          <div className="w-half pr-50 w-md-full mb-md-50">
            <BigTitle className="mb-20">{title}</BigTitle>
            <Paragraph className="mb-30">{description}</Paragraph>
            <Flex className="d-md-none">
              {socialContact &&
                socialContact.map((item, index) => {
                  const { icon, title, url, tel } = item.fields;

                  return (
                    <SocialMedia
                      className="mr-20"
                      key={index}
                      target="_blank"
                      href={url ? url : "tel:" + tel}
                    >
                      <Image source={icon.fields.file.url} />
                      <Tag color={Colors.$primaryDarkColor}>{title}</Tag>
                    </SocialMedia>
                  );
                })}
            </Flex>
          </div>
          <div className="w-half w-md-full p-relative">
            <ContactForm ref={formRef} onSubmit={handleSubmit}>
              <SubTitle
                color={Colors.$primaryColor}
                className="text-center mb-70 mb-md-50 mb-sm-30"
              >
                {contactFormTitle}
              </SubTitle>
              <InputLayout>
                <input type="text" name="name" placeholder="Emri" />
                <input type="text" name="surname" placeholder="Mbiemri" />
              </InputLayout>
              <InputLayout>
                <input type="email" name="email" placeholder="E-maili" />
                <input type="text" name="number" placeholder="Numri" />
              </InputLayout>
              <InputLayout>
                <textarea placeholder="Mesazhi" name="subject" />
              </InputLayout>
              <InputLayout className="d-flex justify-end align-center">
                {alertInfo.message && (
                  <p className={`mr-30 ${alertInfo.type}`}>
                    {alertInfo.message}
                  </p>
                )}
                {buttonStatus === "loading" ? (
                  "Duke Dërguar..."
                ) : buttonStatus === "disabled" ? (
                  ""
                ) : (
                  <button type="submit" className="btn-close">
                    Dërgo
                  </button>
                )}
              </InputLayout>
            </ContactForm>
          </div>
        </Container>
      </Content>
      <Footer>
        <Container className="p-relative d-flex w-full">
          <SocialMedias className="mr-md-20">
            <Paragraph>{socialMediaBoxTitle}</Paragraph>
            {socialMedias &&
              socialMedias.map((item, index) => {
                const { icon, title, url, tel } = item.fields;
                return (
                  <SocialMedia
                    key={index}
                    target="_blank"
                    href={url ? url : "tel:" + tel}
                  >
                    <Image source={icon.fields.file.url} />
                    <Tag color={Colors.$primaryDarkColor}>{title}</Tag>
                  </SocialMedia>
                );
              })}
          </SocialMedias>
          {location && (
            <SocialMedias className="d-flex align-center">
              <Paragraph className="mb-30">
                {socialMediaLocationBoxTitle}
              </Paragraph>
              <a target="_blank" rel="noreferrer" href={location}>
                <Icon icon="location" />
              </a>
            </SocialMedias>
          )}
        </Container>
        <Icon icon="contactFooter" />
      </Footer>
    </ContactHeroContainer>
  );
};

export default ContactHero;
