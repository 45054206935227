import { NavLink } from "react-router-dom";
import BStradeLogo from "../../assets/images/BsTradeLogo.png";
import BStradeLogoInver from "../../assets/images/BsTradeLogoInvert.png";
import { LogoContainer } from "../../layouts/layouts";
import Image from "../image";

const Logo = ({ className, invert, headerStatus }) => {
  return (
    <LogoContainer className={className} headerStatus={headerStatus}>
      <NavLink to="/" className={"d-flex p-relative"}>
        <Image
          source={invert || headerStatus ? BStradeLogoInver : BStradeLogo}
          classList={`w-full h-full object-contain ${
            invert || headerStatus ? "invert" : ""
          }`}
          alt="logo"
        />
      </NavLink>
    </LogoContainer>
  );
};

export default Logo;
