import styled from "@emotion/styled";
import { Breakpoints, Transitions } from "../../assets/variables/variables";
import { Link } from "react-router-dom";

export const ProductContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 30px;
  padding-right: 7px;
  padding-left: 7px;
`;

export const ProductImgContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 255px;
  margin-bottom: 10px;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: 0.3s ${Transitions.$superSmooth};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .wishlist {
        opacity: 1;
        transform: translate(50%, 0);
      }

      img {
        transform: scale(1.2);
      }
    }
  }

  .wishlist-active {
    opacity: 1;
    transform: translate(50%, 0);
    top: 10px;
    bottom: initial;
    right: 30px;
  }

  ${(props) => props.outOfStock && '&:before {content: ""}'};

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.2;
    background-color: #000;
    width: 100%;
    height: 100%;
    z-index: 9;
  }

  p {
    position: absolute;
    left: 50%;
    top: 50%;
    color: #000;
    background-color: #fff;
    padding: 5px 30px;
    white-space: nowrap;
    border-radius: 6px;
    z-index: 10;
    opacity: 0.9;
    transform: translate(-50%, -50%) rotate(-35deg);
  }

  @media only screen and (max-width: ${Breakpoints.$large}) {
    height: 230px;
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    height: 210px;
  }
`;

export const PriceInputField = styled.input`
  border: none;
  outlin: none;
`;

export const WishlistContainer = styled.div`
  position: absolute;
  bottom: 8px;
  right: 50%;
  opacity: 0;
  padding: 3px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  transform: translate(50%, 30%);
  background-color: #fff;
  transition: 0.2s ${Transitions.$linearSmooth};

  @media (max-width: ${Breakpoints.$medium}) {
    opacity: 1;
    transform: translate(50%, 0);
    top: 10px;
    bottom: initial;
    right: 30px;
  }
`;

export const WishBtn = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s ${Transitions.$superSmooth};

  svg {
    width: 20px;
    height: 20px;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      transform: scale(1.2);
    }
  }
`;
