import React, { useEffect, useState } from "react";
import DynamicComponent from "../components/dynamic-component";
import { getData, pageIds } from "../config/data";
import Loader from "../components/loader";
import { CloseBtn, PageTransition } from "../layouts";
import { scrollToTop } from "../config/functions";
import {
  ArrowSlider,
  ImageCount,
  SliderModal,
} from "../components/our-slider/layouts";
import { useExtrasData } from "../Contexts/ExtrasContext";
import Image from "../components/image";
import Icon from "../assets/icons/icon";

const About = () => {
  const [data, setData] = useState(null);
  const [pageActive, setPageActive] = useState(false);
  const { modalImage, setModalImage, modalSliderImages } = useExtrasData();

  // console.log(modalImage, "modalSliderImages");

  const config = {
    page: pageIds.about,
    clear: true,
  };

  useEffect(() => {
    const fetchData = async () => {
      const { result, error } = await getData(config);

      if (error) {
        console.error("Error:", error);
        // Handle the error as needed
      }
      setData(result.content);
    };

    fetchData();
  }, []);

  useEffect(() => {
    scrollToTop({ behavior: "instant" });
    setTimeout(() => {
      if (data) {
        setPageActive(true);
      }
    }, 150);
  }, [data]);

  useEffect(() => {
    document.body.style.overflowY = modalImage ? "hidden" : "visible";
  }, [modalImage]);

  function setNextPrevImage(type) {
    if (modalSliderImages) {
      if (modalSliderImages.length > modalImage.index + 1 && type === "next") {
        setModalImage({
          url: modalSliderImages[modalImage.index + 1].fields.file.url,
          index: modalImage.index + 1,
        });
      } else if (modalImage.index > 0 && type === "prev") {
        setModalImage({
          url: modalSliderImages[modalImage.index - 1].fields.file.url,
          index: modalImage.index - 1,
        });
      } else {
        return;
      }
    }
  }

  if (!data) return <Loader />;

  return (
    <>
      {modalImage && (
        <SliderModal>
          <ImageCount>
            {modalImage && <p>{modalImage.index + 1}</p>}
            {<span>/</span>}
            {modalSliderImages && <p>{modalSliderImages.length}</p>}
          </ImageCount>
          <ArrowSlider
            className="arrow-slider"
            onClick={() => setNextPrevImage("prev")}
            type="main"
          >
            <Icon icon="arrowLeft" />
          </ArrowSlider>
          <CloseBtn className="close-btn" onClick={() => setModalImage(null)} />
          <Image source={modalImage.url} />
          <ArrowSlider
            className="arrow-slider"
            onClick={() => setNextPrevImage("next")}
            type="main"
          >
            <Icon icon="arrowRight" />
          </ArrowSlider>
        </SliderModal>
      )}
      <PageTransition className={`${pageActive ? "active" : ""}`}>
        <DynamicComponent listOfComponentsType={data} />
      </PageTransition>
    </>
  );
};

export default About;
