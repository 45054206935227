export const dropdownOptions = [
  { label: "Radhit produktet", value: null },
  {
    label: "Çmimi: Ulët në të lartë",
    value: "priceASC",
    foreignKey: "price_ASC",
  },
  {
    label: "Çmimi: Lartë në të Ulët",
    value: "priceDESC",
    foreignKey: "price_DESC",
  },
  {
    label: "Data: Produktet më të reja",
    value: "dateDESC",
    foreignKey: "sys_publishedAt_DESC",
  },
  {
    label: "Data: Produktet më të vjetra",
    value: "dateASC",
    foreignKey: "sys_publishedAt_ASC",
  },
];
