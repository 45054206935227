import styled from "@emotion/styled";
import { Breakpoints, Transitions } from "../../assets/variables/variables";

export const SearchContainer = styled.form`
  display: flex;
  margin-right: 30px;
  width: 100%;
  border: 1px solid #dddfe4;
  border-radius: 6px;
  padding: 2px;
  ${(props) =>
    props.headerStatus === true
      ? "z-index: 101; input { background-color: transparent; color: #fff; } svg path { fill: #fff}"
      : ""};
`;

export const SearchBox = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  width: 30px;
  height: 30px;
  outline: none;
  border: none;
  cursor: pointer;
  background: transparent;

  svg {
    width: 17px;
    height: 16px;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 3px 0px 3px 10px;
  outline: none;
  border: none;
  background-color: #fff;
  color: #000;
  font-weight: 100;
  font-size: 12px;
`;

export const SearchTitle = styled.div`
  display: flex;
  padding: 50px 0;
  align-items: baseline;

  h3 {
    font-weight: 500;
    font-family: "Raleway-Light";
    display: flex;
  }

  p {
    font-family: "Raleway-Bold";
    padding-left: 5px;
    font-size: 32px;
  }

  @media (max-width: ${Breakpoints.$medium}) {
    p {
      font-size: 25px;
    }
  }
`;

export const NavLinks = styled.div`
  position: relative;
  display: flex;
  align-center;

  @media only screen and (max-width: ${Breakpoints.$small}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed; 
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    background-color: rgb(0 0 0 / 90%);
    padding: 100px 40px 100px 30px;
    transform: translateX(-10%);
    transition: .3s ${Transitions.$superSmooth};

    &.active { 
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
  }
`;
