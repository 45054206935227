export const SendBuyMessage = (type, data) => {
  const {
    text = null,
    slug = null,
    code = null,
    title = null,
    price = null,
    email = null,
    subject = null,
    phoneNumber = null,
  } = data;

  const formattedMessage = text
    .replace(/##title##/g, title)
    .replace(/##code##/g, code)
    .replace(/##price##/g, price + "€")
    .replace(/##link##/g, "https://bs-trade.store/products/" + slug);
  const message = encodeURIComponent(formattedMessage);

  // const viberLink = `viber://chat?number=${phoneNumber}&draft=${message}`;
  const viberLink = `viber://pa?chatURI=${phoneNumber}&text=${message}`;
  const whatsappUrl =
    "https://api.whatsapp.com/send?phone=" + phoneNumber + "&text=" + message;

  const BuyMethod = {
    email: () => {
      window.location.href = `mailto:${email}?subject=${subject}&body=${message}`;
    },
    viber: () => {
      window.location.href = viberLink;
    },
    whatsapp: () => {
      window.location.href = whatsappUrl;
    },
  };

  return BuyMethod[type]();
};

export function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const scrollToTop = ({ behavior = "smooth" }) => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: behavior,
  });
};
