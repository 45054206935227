import React, { useState } from "react";
import { Block } from "../../layouts";
import { TabsContainer, Tab } from "./layouts";
import { Content } from "../../layouts/layouts";
import RenderText from "../render-text";
import { useExtrasData } from "../../Contexts/ExtrasContext";

const SingleProductDetails = ({ data }) => {
  const { extrasData } = useExtrasData();

  const [activeModule, setActiveModule] = useState({
    type: "description",
    id: 0,
  });

  const detailsButtons = [
    {
      active: data.description && true,
      type: "description",
      title:
        extrasData && extrasData.singleProductDetailsFirstTitle
          ? extrasData.singleProductDetailsFirstTitle
          : "Përshkrimi",
    },
    {
      active: data.specifications && true,
      type: "specification",
      title: extrasData?.singleProductDetailsSecondTitle
        ? extrasData.singleProductDetailsSecondTitle
        : "Specifikat",
    },
  ];

  function handleDetailsTabs(type, index) {
    setActiveModule({ type: type, id: index });
  }

  return (
    <Block className="mb-70 w-full mb-md-50 mb-sm-30">
      <div className="w-full">
        <TabsContainer>
          {detailsButtons &&
            detailsButtons.map((button, index) => {
              const {
                type = null,
                title = "Write a title",
                active = false,
              } = button;

              return active ? (
                <Tab
                  key={index}
                  onClick={() => handleDetailsTabs(type, index)}
                  className={`${index === activeModule.id ? "active" : ""}`}
                >
                  {title}
                </Tab>
              ) : null;
            })}
        </TabsContainer>
        <Content>
          {data.description &&
            activeModule.type === "description" &&
            RenderText(data.description)}

          {data.specifications &&
            activeModule.type === "specification" &&
            RenderText(data.specifications)}
        </Content>
      </div>
    </Block>
  );
};

export default SingleProductDetails;
