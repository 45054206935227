import React, { useEffect, useState } from "react";
import { Container } from "../../layouts";
import Logo from "../../components/logo";
import Navigation from "../navigation";
import { HeaderContainer } from "../../layouts/layouts";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useExtrasData } from "../../Contexts/ExtrasContext";
import { Colors } from "../../assets/variables/variables";
import HeaderBurger from "./header-burger";

const Header = ({ setIsHomepage }) => {
  const [helmetData, setHelmetData] = useState("");
  const [headerStatus, setHeaderStatus] = useState(false);
  const history = useLocation();
  const { extrasData } = useExtrasData();

  useEffect(() => {
    if (extrasData) {
      setHelmetData(extrasData);
    }
  }, [extrasData]);

  useEffect(() => {
    if (history.pathname === "/") {
      setIsHomepage(true);
    } else {
      setIsHomepage(false);
    }
  }, [history.pathname, setIsHomepage]);

  const toggleHeader = () => {
    if (window.innerWidth < 768) {
      setHeaderStatus(!headerStatus);
      document.body.style.overflowY = !headerStatus ? "hidden" : "visible";
    }
  };

  return (
    <div className="background-w p-relative w-full">
      <Container className="p-relative">
        {helmetData && (
          <Helmet>
            <title>{extrasData.websiteTitle}</title>
            <link rel="canonical" href={extrasData.websiteUrl} />
            <meta name="theme-color" content={Colors.$primaryColor} />
            <meta name="robots" content="index, follow" />
            <meta
              name="title"
              property="og:title"
              content={extrasData.websiteTitle}
            />
            <meta name="twitter:title" content={extrasData.websiteTitle} />
            <meta
              name="url"
              property="og:url"
              content={extrasData.websiteUrl}
            />
            <meta name="type" property="og:type" content="website" />
            <meta
              name="description"
              property="og:description"
              content={extrasData.websiteDescription}
            />
            <meta
              name="twitter:description"
              content={extrasData.websiteDescription}
            />
            <meta
              name="image"
              property="og:image"
              content={extrasData.websiteOgImage.fields.file.url}
            />
            <meta
              name="twitter:image"
              content={extrasData.websiteOgImage.fields.file.url}
            />
            <meta name="twitter:card" content="summary_large_image" />
            {extrasData.websiteKeywords && (
              <meta
                name="keywords"
                content={extrasData.websiteKeywords.join(",")}
              />
            )}
          </Helmet>
        )}
        <HeaderContainer className="header" headerStatus={headerStatus}>
          <Logo headerStatus={headerStatus} />
          <Navigation headerStatus={headerStatus} toggleHeader={toggleHeader} />
          <HeaderBurger
            classList={headerStatus ? "active" : ""}
            toggleHeader={toggleHeader}
          />
        </HeaderContainer>
      </Container>
    </div>
  );
};

export default Header;
