import styled from "@emotion/styled";
import { Breakpoints } from "../../assets/variables/variables";

export const RelatedProductsContainer = styled.div`
  background-color: #f7f9fb;
  padding: 70px 0;

  .col-3 {
    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  @media only screen and (max-width: ${Breakpoints.$large}) {
    padding: 50px 0;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    padding: 30px 0;
  }
`;
