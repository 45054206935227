import styled from "@emotion/styled";
import { Breakpoints, Transitions } from "../../../assets/variables/variables";

export const Burger = styled.div`
  display: none;
  position: relative;
  flex: 0 0 30px;
  width: 30px;
  height: 24px;
  cursor: pointer;

  span {
    width: 100%;
    top: 0;
    left: 0;
    height: 3px;
    position: absolute;
    background-color: #000;
    transition: 0.4s ${Transitions.$superSmooth};

    &:first-of-type {
      top: 0;
      width: 70%;
    }

    &:nth-of-type(2) {
      top: 10px;
      left: initial;
      right: 0;
      transform: translatY(-50%);
    }

    &:last-of-type {
      top: 21px;
    }
  }

  &.active {
    z-index: 100;

    span {
      background-color: #fff;

      &:first-of-type {
        top: 10px;
        width: 100%;
        transform: rotate(45deg);
      }

      &:nth-of-type(2) {
        width: 0;
        right: 0;
      }

      &:last-of-type {
        top: 10px;
        transform: rotate(-45deg);
      }
    }
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    display: block;
  }
`;
