import React from "react";
import PropTypes from "prop-types";
import { CheckboxContainer, CheckboxInput, CheckboxLabel } from "./layouts";

const CustomCheckbox = ({ id, name, value, label, checked, onChange }) => {
  return (
    <CheckboxContainer>
      <CheckboxLabel className="custom-checkbox">
        <CheckboxInput
          type="checkbox"
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <span>
          <p>{label}</p>
        </span>
      </CheckboxLabel>
    </CheckboxContainer>
  );
};

CustomCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CustomCheckbox;
