import React from "react";
import { PaginateNumber, PrevNext } from "./layouts";
import Icon from "../../assets/icons/icon";

function getPageNumbers(totalPosts, postsPerPage) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  return pageNumbers;
}

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const prevDisabled = currentPage === 1;
  const nextDisabled = currentPage === Math.ceil(totalPosts / postsPerPage);
  const pageNumbers = getPageNumbers(totalPosts, postsPerPage);
  const ellipsis = <span key="ellipsis">...</span>;

  const getPaginatedPageNumbers = () => {
    const visiblePages = window.innerWidth < 768 ? 3 : 6;
    const totalPages = pageNumbers.length;

    if (totalPages <= visiblePages) {
      return pageNumbers;
    }

    const firstPage = Math.max(currentPage - Math.floor(visiblePages / 2), 1);
    const lastPage = Math.min(firstPage + visiblePages - 1, totalPages);
    let pagesToShow = pageNumbers.slice(firstPage - 1, lastPage);

    if (firstPage > 1) {
      pagesToShow = [1, ellipsis, ...pagesToShow];
    }

    if (lastPage < totalPages) {
      pagesToShow = [...pagesToShow, ellipsis, totalPages];
    }

    return pagesToShow;
  };

  return (
    <nav className="d-flex justify-center">
      <PrevNext
        disabled={prevDisabled}
        onClick={() => paginate(currentPage - 1)}
      >
        <Icon icon="arrowLeft" />
        <span>Prapa</span>
      </PrevNext>

      {getPaginatedPageNumbers().map((number, index) => {
        const isEllipsis = typeof number === "object";

        return (
          <PaginateNumber
            className={`${currentPage === number ? "active" : ""}`}
            onClick={() => !isEllipsis && paginate(number)}
            key={index}
          >
            {isEllipsis ? number : <span>{number}</span>}
          </PaginateNumber>
        );
      })}

      <PrevNext
        disabled={nextDisabled}
        onClick={() => paginate(currentPage + 1)}
      >
        <span>Para</span>
        <Icon icon="arrowRight" />
      </PrevNext>
    </nav>
  );
};

export default Pagination;
