import React, { useEffect, useState } from "react";
import { Container, PageTransition } from "../layouts";
import { BigTitle } from "../layouts/layouts";
import styled from "@emotion/styled";
import { useExtrasData } from "../Contexts/ExtrasContext";

const ErrorContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Error = () => {
  const { extrasData } = useExtrasData();
  const [pageActive, setPageActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPageActive(true);
    }, 350);
  }, []);

  return (
    <ErrorContainer>
      <PageTransition className={`${pageActive ? "active" : ""}`}>
        <Container>
          {extrasData && (
            <BigTitle className="error-title">
              {extrasData.pageNotFoundText}
            </BigTitle>
          )}
        </Container>
      </PageTransition>
    </ErrorContainer>
  );
};

export default Error;
