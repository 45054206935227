import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import {
  Breakpoints,
  Colors,
  Transitions,
} from "../../assets/variables/variables";

export const CategoryCard = styled(Link)`
  margin-bottom: 50px;
  width: 20%;
  position: relative;
  overflow: hidden;

  &:hover {
    h3 {
      color: ${Colors.$primaryColor};

      &:before {
        width: 80px;
        opacity: 1;
      }
    }

    img {
      transform: scale(1.05);
    }
  }

  @media only screen and (max-width: ${Breakpoints.$large}) {
    margin-bottom: 30px;
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    width: 33%;
  }
`;

export const CategoryCardImage = styled.div`
  aspect-ratio: 1/1;
  padding: 25px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s ${Transitions.$linearSmooth};
  }

  @media only screen and (max-width: ${Breakpoints.$large}) {
    padding: 15px;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    padding: 10px;
  }

  @media only screen and (max-width: ${Breakpoints.$extraSmall}) {
    padding: 5px;
  }
`;

export const CategoryCardTtile = styled.h3`
  position: relative;
  text-align: center;
  width: 100%;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2em;
  color: #000;
  font-family: rubik, Sans-serif;
  transition: 0.3s ${Transitions.$linearSmooth};

  &:before {
    content: "";
    position: absolute;
    width: 30px;
    opacity: 0;
    height: 2px;
    bottom: 0;
    background-color: ${Colors.$primaryColor};
    transition: 0.3s ${Transitions.$superSmooth};
  }

  @media only screen and (max-width: ${Breakpoints.$large}) {
    font-size: 25px;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    font-size: 18px;
  }
`;

export const CategoriesContainer = styled.div`
  padding: 70px 0;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  background-color: #fbf3e2;

  @media only screen and (max-width: ${Breakpoints.$large}) {
    padding: 50px 0;
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    padding: 30px 0;
  }
`;

export const CategoriesTitle = styled.h2`
  font-family: rubik, Sans-serif;
  width: 100%;
  font-size: 35px;
  text-align: center;
  padding: 0 70px 70px 70px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: ${Breakpoints.$large}) {
    padding-bottom: 50px;
    font-size: 32px;
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    padding: 0 30px 30px 30px;
    font-size: 28px;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    font-size: 25px;
    padding: 0 15px 30px 15px;
  }
`;
