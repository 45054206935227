import React, { useEffect, useState } from "react";
import ContactHero from "../components/contact-hero";
import Loader from "../components/loader";
import { getData, pageIds } from "../config/data";
import { PageTransition } from "../layouts";
import { scrollToTop } from "../config/functions";

const Contact = () => {
  const [data, setData] = useState(null);
  const [pageActive, setPageActive] = useState(false);

  const config = {
    page: pageIds.contact,
    clear: true,
  };

  useEffect(() => {
    const fetchData = async () => {
      const { result, error } = await getData(config);
      if (error) {
        console.log("Error", error);
      }
      setData(result);
    };
    fetchData();
  }, []);

  useEffect(() => {
    scrollToTop({ behavior: "instant" });
    setTimeout(() => {
      if (data) {
        setPageActive(true);
      }
    }, 150);
  }, [data]);

  if (!data) return <Loader />;

  return (
    <PageTransition className={`${pageActive ? "active" : ""}`}>
      <ContactHero data={data} />;
    </PageTransition>
  );
};

export default Contact;
