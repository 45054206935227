import { Burger } from "./layouts";

const HeaderBurger = ({ classList, toggleHeader }) => {
  return (
    <Burger className={`${classList} header-burger`} onClick={toggleHeader}>
      <span />
      <span />
      <span />
    </Burger>
  );
};

export default HeaderBurger;
