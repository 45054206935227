import styled from "@emotion/styled";
import { Breakpoints, Transitions } from "../../assets/variables/variables.js";
import { Link } from "react-router-dom";

export const HeroImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right top;
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    img {
      object-position: center top;
    }
  }
`;

export const HeroContent = styled.div`
  width: 50%;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;

  h1,
  p {
    text-align: right;
  }

  @media only screen and (max-width: ${Breakpoints.$large}) {
    width: 70%;
    padding-left: 0;
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    width: 100%;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    padding-left: 30px;
  }
`;

export const HeroContainer = styled.div`
  position: relative;
  display: flex;
  padding: 70px 0;
  height: 100vh;

  @media only screen and (max-width: ${Breakpoints.$small}) {
    padding: 100px 0;
  }
`;

export const HeroButton = styled(Link)`
  position: relative;
  border-radius: 12px 0 12px 0;
  padding: 10px 40px;
  border: 0;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1px;
  box-shadow: 5px 5px 8px 1px #00000061;
  margin-bottom: 30px;
  color: #fff;
  background: linear-gradient(
    90deg,
    rgba(87, 62, 42, 1) 0%,
    rgba(224, 213, 191, 1) 100%
  );
  transition: 0.2s ${Transitions.$superSmooth};

  &:hover {
    box-shadow: 5px 5px 8px 1px #00000092;
    border-radius: 12px;
  }
`;
