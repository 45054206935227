import React, { useState, useEffect } from "react";
import ProductList from "../components/products";
import { getQueryData } from "../config/data";
import { useLocation } from "react-router-dom";
import { ProductsPage } from "../layouts/layouts";
import Loader from "../components/loader";
import { PageTransition } from "../layouts";
import { scrollToTop } from "../config/functions";

const Products = () => {
  const [pageActive, setPageActive] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlSelectedCategories = params.get("categories");
  const minPriceParam = params.get("minPrice");
  const maxPriceParam = params.get("maxPrice");
  const sortingParam = params.get("sort");
  const paginateNumer = params.get("page");
  const [products, setProducts] = useState(null);
  const [categories, setCategories] = useState(null);
  const [minMaxPrice, setMinMaxPrice] = useState(null);
  const [minPrice] = useState(minPriceParam ? Number(minPriceParam) : null);
  const [maxPrice] = useState(maxPriceParam ? Number(maxPriceParam) : null);
  const [selectedCategories] = useState(
    urlSelectedCategories ? urlSelectedCategories.split(",") : null
  );
  const sortTypes = {
    priceASC: "price_ASC",
    priceDESC: "price_DESC",
    dateASC: "sys_publishedAt_ASC",
    dateDESC: "sys_publishedAt_DESC",
  };
  const [sort] = useState(sortingParam ? sortTypes[sortingParam] : null);

  useEffect(() => {
    getQueryData({
      type: "minmaxprice",
    }).then((data) => {
      const result = data.result;

      if (result) {
        setMinMaxPrice({
          maxPrice: result.maxPrice.items[0].price,
          minPrice: result.minPrice.items[0].price,
        });
      }
    });

    if (!products) {
      if (!selectedCategories && !minPrice && !maxPrice && !sort) {
        getQueryData({ type: "products", limit: 500 })
          .then((data) => {
            if (data.result) {
              const result = data.result.productCollection.items;
              setProducts(result);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        getQueryData({
          type: "products",
          categorySlug: selectedCategories,
          minPrice: minPrice,
          maxPrice: maxPrice,
          sort: sort,
        })
          .then((data) => {
            const result = data.result.productCollection.items;
            setProducts(result);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
      getQueryData({ type: "categories" })
        .then((data) => {
          setCategories(data.result.categoryCollection.items);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, []);

  useEffect(() => {
    scrollToTop({ behavior: "instant" });

    setTimeout(() => {
      if (products) {
        setPageActive(true);
      }
    }, 150);
  }, [products]);

  if (!products) return <Loader />;

  return (
    <PageTransition className={`${pageActive ? "active" : ""}`}>
      <ProductsPage>
        {products && (
          <ProductList
            data={products}
            container={true}
            sortParam={sort}
            activeFilters={true}
            categories={categories}
            minMaxPrice={minMaxPrice}
            paginateNumer={paginateNumer}
            priceValuesParam={{ minPrice: minPrice, maxPrice: maxPrice }}
            selectedCategoriesParam={selectedCategories}
          />
        )}
      </ProductsPage>
    </PageTransition>
  );
};

export default Products;
