import React, { useState, useEffect } from "react";
import ProductList from "../components/products";
import { Container, PageTransition } from "../layouts";
import { getQueryData } from "../config/data";
import { SearchTitle } from "../components/navigation/layouts";
import { useSearchParams } from "react-router-dom";
import { useExtrasData } from "../Contexts/ExtrasContext";
import Loader from "../components/loader";
import { scrollToTop } from "../config/functions";

const Search = () => {
  const { extrasData } = useExtrasData();
  const [products, setProducts] = useState("loading");
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get("q") || "";
  const [pageActive, setPageActive] = useState(false);

  useEffect(() => {
    setProducts("loading");

    getQueryData({
      type: "products",
      searchTerm: searchQuery,
    })
      .then((data) => {
        const dataResult = data.result.productCollection.items;
        const result = dataResult.length > 0 ? dataResult : null;
        setProducts(result);
      })
      .catch((error) => {
        console.error("Error: 'e903'", error);
      });
  }, [searchQuery]);

  useEffect(() => {
    scrollToTop({ behavior: "instant" });
    setTimeout(() => {
      if (products) {
        setPageActive(true);
      }
    }, 150);
  }, [products]);

  return (
    <PageTransition className={`${pageActive ? "active" : ""}`}>
      <Container>
        <SearchTitle>
          <h3>
            Rezultatet e gjetura nga: <p>{searchQuery}</p>
          </h3>
        </SearchTitle>
        {products === "loading" ? (
          <Loader />
        ) : products ? (
          <ProductList data={products} activeFilters={false} />
        ) : (
          <SearchTitle>
            {extrasData ? (
              <h3>{extrasData.noProductsFound}</h3>
            ) : (
              <h3>Nuk ka produkte me këtë kërkim</h3>
            )}
          </SearchTitle>
        )}
      </Container>
    </PageTransition>
  );
};

export default Search;
