import styled from "@emotion/styled";
import { keyframes, css } from "@emotion/react";
import { Colors, Transitions } from "../../assets/variables/variables";

const moveImageAnimation = keyframes`
  0% {
    left: 0;
  }
  100% {
    left: 50%;
  }
`;

const appearTextAnimation = keyframes`
  0% {
    transform: translateY(-30px);
    opacity: 0;
    visibility: hidden;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
`;

const mainTextAnimation = keyframes`
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
`;

const mainTextContainerAnimation = keyframes`
  0% {
    height: 200px;
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
    height: 600px;
  }
`;

export const CompanyHead = styled.div`
  display: flex;
  flex-direction: column;
  margin: 70px 0;
  
  h1 {
    color: ${Colors.$primaryColor};
    padding-bottom: 15px;
  }
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
  flex: 0 50%;
  position: relative;
  height: 300px;
`;

export const BlockImage = styled.div`
  position: absolute;
  flex: 0 50%;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  z-index: 2;
  animation: ${moveImageAnimation} 0.8s ${Transitions.$superSmooth} forwards
    ${(props) => (props.render === 1 ? "2s" : props.render === 2 ? "3s" : "5s")};

  img {
    width: 100%;
    height: 100%;
  }
`;

export const BlockTitle = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.solid ? "#fff" : Colors.$primaryLightColor};
  text-align: center;
  ${(props) =>
    props.solid && "cursor: pointer; &:hover{svg {transform: scale(1.3)}}"};

  h2 {
    color: ${(props) => (props.solid ? Colors.$primaryColor : "#fff")};
    font-weight: 500;
    padding-bottom: 15px;
    line-height: 1.1;
  }

  svg {
    opacity: 0.1;
    position: absolute;
    left: 30px;
    top: 50px;
    width: 150px;
    height: 200px;
    transition: 0.3s ${Transitions.$linearSmooth};
    ${(props) =>
      props.solid &&
      "opacity: 1; width: 50px; height: 50px; width: 50px; position: relative; top: 0; left: 0; margin-bottom: 15px;"}
  }

  a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  ${(props) =>
    props.animate &&
    css`
      svg,
      h2 {
        opacity: 0;
        visibility: hidden;
        animation: ${appearTextAnimation} 0.8s ${Transitions.$superSmooth}
          forwards
          ${props.render === 1 ? "2.5s" : props.render === 2 ? "3.5s" : "5.2s"};
      }

      svg {
        opacity: 0.1 !important;
      }
    `}
`;

export const BlockContent = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.animate &&
    css`
      opacity: 0;
      visibility: hidden;
      animation: ${appearTextAnimation} 0.8s ${Transitions.$superSmooth}
        forwards
        ${props.render === 1 ? "2.5s" : props.render === 2 ? "3.5s" : "5.2s"};
    `}

  ${(props) =>
    props.reverse &&
    "flex-direction: column; text-align: center; h2 {color:" +
      Colors.$primaryColor +
      "};"}

  svg {
    opacity: 0.1;
    position: absolute;
    left: 30px;
    top: 50px;
    width: 150px;
    height: 200px;
  }

  ${(props) =>
    props.solid &&
    "background-color: " +
      Colors.$primaryLightColor +
      "; svg{opacity: 1; position: relative; top: 0; left: 0; margin-bottom: 15px; path{fill: #fff;}}"};
`;

export const CompanyImage = styled.div`
  height: 500px;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const GeneralBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 200px;
  background-color: ${Colors.$primaryLightColor};
  flex-direction: column;
  z-index: 2;

  svg {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;

    path {
      fill: #fff;
    }
  }

  h2 {
    color: #fff;
    font-weight: 500;
    padding-bottom: 15px;
    line-height: 1.1;
  }
`;

export const GeneralBlockContent = styled.div`
  top: 0;
  height: 200px;
  padding-top: 200px;
  position: absolute;
  width: 100%;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  background-color: ${Colors.$primaryLightColor};

  div {
    border: none;
    color: #fff;
    font-size: 16px;
  }

  ${(props) =>
    props.animate &&
    css`
      animation: ${mainTextContainerAnimation} 0.8s ${Transitions.$superSmooth}
        forwards 4s;

      div {
        opacity: 0;
        visibility: hidden;
        animation: ${mainTextAnimation} 0.8s ${Transitions.$superSmooth}
          forwards 4.5s;
      }
    `}
`;

export const GeneralCompanyImage = styled.div`
  height: 600px;
  position: absolute;
  left: 0;
  bottom: 0;

  img {
    width: 100%;
    height: 100%;
  }
`;
