import { useEffect, useState } from "react";
import Slider from "react-slick";
import Image from "../image";
import Icon from "../../assets/icons/icon";
import {
  ArrowSlider,
  SliderContainer,
  SliderMain,
  SliderTip,
  Thumbnails,
} from "./layouts";
import { useExtrasData } from "../../Contexts/ExtrasContext";

export function MainNextArrow(props) {
  const { onClick, type } = props;
  return (
    <ArrowSlider className="arrow-slider" type={type} onClick={onClick}>
      <Icon icon="arrowRight" />
    </ArrowSlider>
  );
}

export function MainPrevArrow(props) {
  const { onClick, type } = props;

  return (
    <ArrowSlider className="arrow-slider" type={type} onClick={onClick}>
      <Icon icon="arrowLeft" />
    </ArrowSlider>
  );
}

const settings = {
  infinite: true,
  centerPadding: "0px",
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: true,
  swipe: false,
  focusOnSelect: true,
  nextArrow: <MainNextArrow type={"main"} />,
  prevArrow: <MainPrevArrow type={"main"} />,
};

const thumbnailSettings = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  swipeToSlide: true,
  centerMode: true,
  lazyLoad: true,
  focusOnSelect: true,
  variableWidth: true,
  nextArrow: <MainNextArrow type={"thumbnail"} />,
  prevArrow: <MainPrevArrow type={"thumbnail"} />,
};

const OurSlider = (data) => {
  const [sliderData, setSliderData] = useState(null);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const { setModalImage, setModalSliderImages } = useExtrasData();

  useEffect(() => {
    if (data) {
      setSliderData(data.fields.sliderImages);
      setModalSliderImages(data.fields.sliderImages);
    }
  }, [data]);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  return (
    <SliderContainer>
      <SliderMain>
        <Slider
          {...settings}
          asNavFor={nav2}
          ref={(slider) => setSlider1(slider)}
        >
          {sliderData &&
            sliderData.map((item, index) => (
              <div
                key={index}
                onClick={() =>
                  setModalImage({ url: item.fields.file.url, index })
                }
              >
                <Image source={item.fields.file.url} />
              </div>
            ))}
        </Slider>
      </SliderMain>
      <SliderTip>Këshillë: Klikoni në foto për të parë të gjithën</SliderTip>
      <Thumbnails>
        <Slider
          {...thumbnailSettings}
          asNavFor={nav1}
          ref={(slider) => setSlider2(slider)}
        >
          {sliderData &&
            sliderData.map((item, index) => (
              <div key={index}>
                <hr />
                <Image source={item.fields.file.url} size="medium" />
                <hr />
              </div>
            ))}
        </Slider>
      </Thumbnails>
    </SliderContainer>
  );
};

export default OurSlider;
