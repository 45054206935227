import { useState } from "react";
import { Container } from "../../layouts";
import { CategoriesTitle } from "../our-categories/layouts";
import { GeneralIcon } from "../../layouts/layouts";
import Image from "../image";
import {
  Category,
  CategoryDescription,
  CategoryTitle,
  MixedCategoriesContainer,
  MixedCategoriesContent,
} from "./layouts";

const MixedCategories = (data) => {
  const [categoriesData, setCategoriesData] = useState(null);

  useState(() => {
    if (data) {
      setCategoriesData(data.fields);
    }
  }, []);

  if (!categoriesData) return;

  return (
    <MixedCategoriesContainer>
      <Container>
        {categoriesData.title && (
          <>
            <CategoriesTitle>
              {categoriesData.title}
              <GeneralIcon />
            </CategoriesTitle>
          </>
        )}
        <MixedCategoriesContent>
          {categoriesData &&
            categoriesData.content.map((category, index) => {
              const {
                slug,
                icon = null,
                title = "/",
                description = null,
              } = category.fields;

              return (
                // <Category key={index} to={`/products?categories=${slug}`}>
                <Category key={index}>
                  <Image source={icon.fields.file.url} size="verticalFull" />
                  <CategoryDescription className="cat-description">
                    {description}
                  </CategoryDescription>
                  <CategoryTitle>{title}</CategoryTitle>
                </Category>
                // </Category>
              );
            })}
        </MixedCategoriesContent>
      </Container>
    </MixedCategoriesContainer>
  );
};

export default MixedCategories;
