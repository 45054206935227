import styled from "@emotion/styled";
import {
  Breakpoints,
  Colors,
  Transitions,
} from "../../assets/variables/variables";

export const PaginateNumber = styled.div`
  display: inline-block;
  position: relative;
  padding: 8px 10px;
  margin: 0 5px;
  line-height: 1;
  cursor: pointer;
  font-size: 18px;
  user-select: none;
  color: ${Colors.grey};
  border-radius: 4px;
  transition: 0.2s ${Transitions.$linearSmooth};

  &:hover {
    background-color: #282c3f;
    span {
      color: #fff;
    }
  }

  span {
    display: inline-block;
    position: relative;
    font-weight: bold;
    line-height: 1;
  }

  &.active {
    background-color: #282c3f;

    &:hover {
      text-decoration: none;
    }

    span {
      color: #fff;
      color: ${Colors.black};
    }
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    padding: 5px 7px;
    margin: 0 3px;
  }
`;

export const PrevNext = styled.button`
  outline: none;
  border: none;
  background: none;
  padding: 0;
  display: flex;
  padding: 8px 10px;
  margin: 0 25px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.$colorGreyMedium};
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s ${Transitions.$linearSmooth};

  &:hover {
    border: 1px solid ${Colors.$colorGrey};
  }

  &:last-of-type {
    svg {
      margin-right: 0;
      margin-left: 7px;
    }
  }

  svg {
    margin-right: 7px;
    width: 15px;
    height: 15px;
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    margin: 0 8px;
  }
`;
