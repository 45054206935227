import React, { useState } from "react";
import "./style.scss";
import { useSearchParams } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";
import { NavLinks, SearchBox, SearchContainer, SearchInput } from "./layouts";
import Icon from "../../assets/icons/icon";

const Navigation = ({ headerStatus, toggleHeader }) => {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get("q") || "";
  const [inputValue, setInputValue] = useState(searchQuery ? searchQuery : "");
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (inputValue) {
      toggleHeader(false);
      navigate(`/search?q=${inputValue}`);
    }
  };

  return (
    <div className="d-flex align-center w-full">
      <SearchContainer onSubmit={handleSubmit} headerStatus={headerStatus}>
        <SearchInput
          type="search"
          placeholder="Kërko..."
          value={inputValue}
          onChange={handleInputChange}
        />
        <SearchBox type="submit">
          <Icon icon="search" />
        </SearchBox>
      </SearchContainer>
      <NavLinks className={headerStatus ? "active" : ""}>
        <NavLink
          onClick={toggleHeader}
          to="/"
          className={"navlink text-decoration-none color-black py-5 px-10"}
        >
          Ballina
        </NavLink>
        <NavLink
          onClick={toggleHeader}
          to="/products"
          className={"navlink text-decoration-none color-black py-5 px-10"}
        >
          Produktet
        </NavLink>
        <NavLink
          onClick={toggleHeader}
          to="/about"
          className={"navlink text-decoration-none color-black py-5 px-10"}
        >
          Rreth nesh
        </NavLink>
        <NavLink
          onClick={toggleHeader}
          to="/contact"
          className={"navlink text-decoration-none color-black py-5 px-10"}
        >
          Kontakti
        </NavLink>
      </NavLinks>
    </div>
  );
};

export default Navigation;
