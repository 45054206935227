import styled from "@emotion/styled";

export const LoaderLayout = styled.div`
  position: relative;
  height: 85vh;

  > div {
    align-items: center;
    justify-content: center;
  }

  .loader {
    height: 60px;
    width: 60px;
    aspect-ratio: 1;
    --_g: no-repeat radial-gradient(farthest-side, #000 90%, #0000);
    background: var(--_g), var(--_g), var(--_g);
    background-size: 25% 25%;
    animation: l17 1s infinite;
  }

  @keyframes l17 {
    0% {
      background-position: 0% -100%, 50% -100%, 100% -100%;
    }
    16.67% {
      background-position: 0% 50%, 50% -100%, 100% -100%;
    }
    33.33% {
      background-position: 0% 50%, 50% 50%, 100% -100%;
    }
    45%,
    55% {
      background-position: 0% 50%, 50% 50%, 100% 50%;
    }
    66.67% {
      background-position: 0% 200%, 50% 50%, 100% 50%;
    }
    83.33% {
      background-position: 0% 200%, 50% 200%, 100% 50%;
    }
    100% {
      background-position: 0% 200%, 50% 200%, 100% 200%;
    }
  }
`;
