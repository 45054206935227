import React, { useEffect, useState } from "react";
import { Container } from "../../layouts";
import { HeroImage, HeroContent, HeroContainer, HeroButton } from "./layouts";
import { BigTitle, Paragraph } from "../../layouts/layouts";
import Image from "../image";

const Hero = (data) => {
  const [heroData, setHeroData] = useState();

  useEffect(() => {
    if (data) {
      setHeroData(data.fields);
    }
  }, [data]);

  if (!heroData) return;
  const {
    title = "",
    description = "",
    image = "",
    buttonText = "See all",
  } = heroData;

  return (
    <HeroContainer>
      <HeroImage>
        <Image source={image.fields.file.url} alt={title} />
      </HeroImage>
      <Container className="d-flex justify-end">
        <HeroContent className="align-right">
          <BigTitle className="mb-50 mb-md-30">{title}</BigTitle>
          <HeroButton to="/products">{buttonText}</HeroButton>
          <Paragraph className="mb-30 w-75 w-md-full"> {description}</Paragraph>
        </HeroContent>
      </Container>
    </HeroContainer>
  );
};

export default Hero;
