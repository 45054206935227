export const productsQuery = `
  query(
    $categorySlug: [String!], 
    $searchTerm: String, 
    $slug: [String!], 
    $sort: [ProductOrder!], 
    $minPrice: Float, 
    $maxPrice: Float, 
    $imagesLimit: Int, 
    $itemsLimit: Int
  ) {
    productCollection (
      limit: $itemsLimit,
      order: $sort, 
      where: {
        productCategories: { slug_in: $categorySlug }
        price_gte: $minPrice
        price_lte: $maxPrice
        OR: [
          { slug_in: $slug }
          { title_contains: $searchTerm }
          { description_contains: $searchTerm }
        ]
      }
    ) {
      items {
        sys {
          id
        }
        code
        slug
        title
        quote
        price
        salePrice
        outOfStock
        description
        specifications
        imagesCollection(limit: $imagesLimit) {
          items {
            title
            fileName
            size
            url
            width
            height
          }
        }
        productCategoriesCollection(limit: 20) {
          items {
            title
            slug
            mixedCategory
          }
        }
      }
    }
  }
`;

// from this query down are note updated and needs to clean.
export const categoryProductsQuery = `
  query($categorySlug: String!, $itemsLimit: Int, $skip: Int, $currentProductSlug: [String]) {
    productCollection(
      where: { productCategories: { slug: $categorySlug }, slug_not_in: $currentProductSlug},
      limit: $itemsLimit, 
      skip: $skip,
    ) {
      items {
        sys {
          id
        }
        code
        slug
        title
        quote
        price
        salePrice
        outOfStock
        description
        specifications
        imagesCollection(limit: 1) {
          items {
            title
            fileName
            size
            url
            width
            height
          }
        }
      }
    }
  }
`;

export const singleCategory = `
  query($categorySlug: String!) {
    categoryCollection(where: { slug: $categorySlug }) {
      items{
        name
        slug
        categoryImageVertical{
          fileName
          url
        }
        categoryImageHorizontal{
          fileName
          url
        }
      }
    }
  }
`;

export const mixedCategoriesQuery = `
  query {
    categoryCollection(where: { mixedCategory: true}, limit: 8) {
    items {
      name
      slug
      categoryImageVertical {
        url
        title
      }
      mixedCategory
    }
    }
  }
`;

export const categoriesQuery = `
  query {
    categoryCollection {
      items {
        sys {
          id
        }
        title
        slug
      }
    }
  }
`;

export const minmaxpriceQuery = `
  query {
    maxPrice: productCollection(
      limit: 1
      order: price_DESC
    ) {
      items {
        price
      }
    }
    
    minPrice: productCollection(
      limit: 1
      order: price_ASC
    ) {
      items {
        price
      }
    }
  }
`;
