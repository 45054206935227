import React, { useState, useRef, useEffect } from "react";
import { Flex } from "../../layouts";
import {
  Paragraph,
  Seperator,
  SmallParagraph,
  Title,
} from "../../layouts/layouts";
import {
  Price,
  BuyButton,
  SalePrice,
  FeaturedImage,
  ImagesContent,
  FeaturedImages,
  CategoriesTitle,
  MixedCategoryTitle,
  SingleProductImage,
  BuyParagraph,
  BuyOpptionsContainer,
  FeaturedButton,
  FeaturedContainer,
} from "./layouts";
import Image from "../image";
import Icon from "../../assets/icons/icon";
import { SendBuyMessage } from "../../config/functions";
import { useExtrasData } from "../../Contexts/ExtrasContext";

const SingleProductHero = ({ data }) => {
  const [buyActive, setBuyActive] = useState(false);
  const [buyTransition, setBuyTransition] = useState(false);
  const [activeImage, setActiveImage] = useState({ index: 0 });
  const { extrasData } = useExtrasData();

  const {
    slug,
    code = null,
    price = null,
    quote = null,
    salePrice = null,
    title = "Product",
    outOfStock = false,
    imagesCollection = null,
    productCategoriesCollection = null,
  } = data;

  const buyDataObj = {
    code,
    slug,
    title,
    price,
    email: extrasData?.buyEmail,
    text: extrasData?.buyMessage,
    subject: extrasData?.buySubject,
    phoneNumber: extrasData?.buyNumber,
  };

  const imagesArray = imagesCollection.items;
  const mixedCategories = productCategoriesCollection.items.filter(
    (category) => category.mixedCategory
  );
  const showArrows = imagesArray.length > 4;
  const scrollContainerRef = useRef(null);
  const isMobile = () => window.innerWidth <= 768;

  useEffect(() => {
    setActiveImage({ image: imagesArray[0].url, index: 0 });
  }, [data]);

  function activeBuyButton() {
    setBuyActive(true);
    setTimeout(() => {
      setBuyTransition(true);
    }, 1);
  }

  function formatPrice(price) {
    const formattedPrice = price.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return `${formattedPrice} €`;
  }

  function changeActivePicture(index) {
    setActiveImage({ image: imagesArray[index].url, index: index });
  }

  const scrollFeaturedImages = (direction) => {
    if (scrollContainerRef.current) {
      const { current: container } = scrollContainerRef;
      const scrollAmount = isMobile()
        ? container.offsetWidth / 2
        : container.offsetHeight / 2;

      if (isMobile() && (direction === "left" || direction === "right")) {
        container.scrollLeft +=
          direction === "left" ? -scrollAmount : scrollAmount;
      } else if (
        !isMobile() &&
        (direction === "left" || direction === "right")
      ) {
        container.scrollTop +=
          direction === "left" ? -scrollAmount : scrollAmount;
      }
    }
  };

  return (
    <Flex className="mb-70 flex-md-column mb-md-50">
      <ImagesContent>
        <FeaturedContainer>
          {showArrows && (
            <FeaturedButton
              className="slider-arrow left-arrow"
              onClick={() => scrollFeaturedImages("left")}
            >
              <Icon icon="arrowTop" />
            </FeaturedButton>
          )}
          <FeaturedImages ref={scrollContainerRef}>
            {imagesArray.map((image, index) => {
              return (
                <FeaturedImage
                  key={index}
                  outOfStock={!outOfStock}
                  className={`${activeImage.index === index ? "active" : ""}`}
                  onClick={() => changeActivePicture(index)}
                >
                  <Image source={image.url} size="small" />
                </FeaturedImage>
              );
            })}
          </FeaturedImages>
          {showArrows && (
            <FeaturedButton
              className="slider-arrow left-arrow"
              onClick={() => scrollFeaturedImages("right")}
            >
              <Icon icon="arrowDown" />
            </FeaturedButton>
          )}
        </FeaturedContainer>
        <SingleProductImage outOfStock={!outOfStock}>
          <Image
            source={activeImage.image}
            classList={"w-full h-full object-contain"}
            alt={"single-product"}
          />
          {!outOfStock && <Paragraph>E shitur</Paragraph>}
        </SingleProductImage>
      </ImagesContent>
      <div className="w-40 pl-50 pl-lg-20 pl-md-0 w-md-full">
        {mixedCategories.length > 0 && (
          <Flex className="mb-30">
            {mixedCategories.map((category, index) => {
              return (
                <MixedCategoryTitle key={index}>
                  {category.title}
                </MixedCategoryTitle>
              );
            })}
          </Flex>
        )}
        {productCategoriesCollection.items &&
          productCategoriesCollection.items.length > 0 && (
            <Flex>
              {productCategoriesCollection.items.map((category, index) => {
                return (
                  <React.Fragment key={index}>
                    <CategoriesTitle
                      to={`/products?categories=${category.slug}`}
                    >
                      {category.title}
                    </CategoriesTitle>
                    {productCategoriesCollection.items.length !== index + 1 && (
                      <CategoriesTitle to="#"> / </CategoriesTitle>
                    )}
                  </React.Fragment>
                );
              })}
            </Flex>
          )}
        <Title className="mb-20 mb-md-30">{title}</Title>
        {quote && <Paragraph className="mb-10">{quote}</Paragraph>}
        {code && (
          <SmallParagraph className="mb-30">
            Kodi i produktit: <strong>{code}</strong>
          </SmallParagraph>
        )}
        <Flex>
          {salePrice && <SalePrice>{formatPrice(salePrice)}</SalePrice>}
          <Price>{formatPrice(price)}</Price>
        </Flex>
        <Seperator />
        {outOfStock && (
          <>
            <Flex className="mb-15">
              <BuyButton type="buy" onClick={activeBuyButton}>
                Blej tani
              </BuyButton>
            </Flex>
            {buyActive && (
              <BuyOpptionsContainer className={buyTransition ? "active" : ""}>
                <BuyParagraph className="w-md-full pb-md-5">
                  Blej produktin nga platforma që dëshironi:
                </BuyParagraph>
                <BuyButton
                  color="#25D366"
                  onClick={() => SendBuyMessage("whatsapp", buyDataObj)}
                >
                  <Icon icon="whatsapp" />
                  WhatsApp
                </BuyButton>
                <BuyButton
                  color="#d03d2f"
                  onClick={() => SendBuyMessage("email", buyDataObj)}
                >
                  <Icon icon="gmail" />
                  Gmail
                </BuyButton>
                <BuyButton color="#fa7e1e">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/_bstrade/"
                  >
                    {""}
                  </a>
                  <Icon icon="instagram" />
                  Instagram
                </BuyButton>
                <BuyButton
                  color="#59267c"
                  onClick={() => SendBuyMessage("viber", buyDataObj)}
                >
                  <Icon icon="viber" />
                  Viber
                </BuyButton>
              </BuyOpptionsContainer>
            )}
          </>
        )}
      </div>
    </Flex>
  );
};

export default SingleProductHero;
