import React, { useState, useEffect } from "react";
import {
  ProductContainer,
  ProductImgContainer,
  WishlistContainer,
  WishBtn,
} from "./layouts";
import { Paragraph, ProductPrice, ProductTitle } from "../../layouts/layouts";
import Icon from "../../assets/icons/icon";
import Image from "../image";
import { scrollToTop } from "../../config/functions";

const Product = ({ size, data }) => {
  const [productOnList, setProductOnList] = useState(false);
  const [localWishlisht] = useState(
    JSON.parse(localStorage.getItem("wishlist"))
  );

  const {
    sys,
    slug,
    price = "",
    title = "",
    salePrice = null,
    outOfStock = false,
    imagesCollection = "",
  } = data;

  function toggleWishlist(e) {
    e.preventDefault();

    const existedWishlist = localStorage.getItem("wishlist")
      ? JSON.parse(localStorage.getItem("wishlist"))
      : [];
    const isProductInWishlist = existedWishlist.includes(sys.id);

    let updatedWishlist = [];

    if (isProductInWishlist) {
      setProductOnList(false);
      updatedWishlist = existedWishlist.filter((id) => id !== sys.id);
    } else {
      setProductOnList(true);
      updatedWishlist = [...existedWishlist, sys.id];
    }
    localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
  }

  function checkIfProductOnWishlist() {
    if (localWishlisht.includes(sys.id)) {
      setProductOnList(true);
    }
  }

  useEffect(() => {
    if (sys && localWishlisht) {
      checkIfProductOnWishlist();
    }
  }, [data, localWishlisht]);

  useEffect(() => {
    scrollToTop({ behavior: "instant" });
  }, [data]);

  return (
    <ProductContainer className={size} to={`/products/${slug}`}>
      <ProductImgContainer outOfStock={!outOfStock}>
        <Image
          size="large"
          source={imagesCollection.items[0].url}
          alt="product"
        />
        {!outOfStock && <Paragraph>E shitur</Paragraph>}
        <WishlistContainer
          className={`wishlist ${productOnList ? "wishlist-active" : ""}`}
        >
          <WishBtn onClick={toggleWishlist}>
            <Icon icon={`${productOnList ? "heartRemove" : "heart"}`} />
          </WishBtn>
        </WishlistContainer>
      </ProductImgContainer>
      <ProductTitle className="pb-5">{title}</ProductTitle>
      <ProductPrice>
        <p>{price}€</p>
        {salePrice && <span>{salePrice}€</span>}
      </ProductPrice>
    </ProductContainer>
  );
};

export default Product;
