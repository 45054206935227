import styled from "@emotion/styled";
import {
  Breakpoints,
  Colors,
  Transitions,
} from "../../assets/variables/variables.js";

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  flex-wrap: wrap;
  margin-bottom: 15px;
  margin-right: 15px;

  @media only screen and (max-width: ${Breakpoints.$large}) {
    width: 30%;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    position: fixed;
    left: 0;
    top: 0;
    width: 40%;
    min-width: 250px;
    height: 100%;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-100%);
    transition: 0.4s ${Transitions.$superSmooth};

    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateX(0%);
    }
  }
`;

export const FilterForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 5px;
  width: 100%;
  // background-color: #f3f3f3;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  @media only screen and (max-width: ${Breakpoints.$small}) {
    height: 100%;
    justify-content: flex-start;
    padding-top: 10px;
  }
`;

export const FilterMobileBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 98;
  visibility: hidden;
  background-color: #000;
  transition: 0.3s ${Transitions.$linearSmooth};

  &.active {
    opacity: 0.6;
    visibility: visible;
  }
`;

export const FilterContainer = styled.div`
  flex-direction: column;
  width: 100%;
  display: flex;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #dfdfe0;
  margin-bottom: 10px;
  border-left: 3px solid ${Colors.$primaryMediumColor};

  &:last-of-type {
    margin-bottom: 0;
  }

  .Dropdwon-root {
    border-radius: 6px;

    &.is-open {
      border-radius: 6px 6px 0 0;
    }
  }

  .Dropdown-option {
    font-size: 16px;

    &.is-selected {
      background-color: ${Colors.$primarySuperLightColor};
      font-weight: 500;
    }
  }

  .Dropdown-menu {
    border-radius: 0 0 6px 6px;
  }

  .Dropdown-control {
    padding: 8px 20px 8px 10px;
  }
`;

export const ReactSliderContainer = styled.div`
  position: relative;
  height: 40px;
`;

export const ReactSliderBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 22px;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: inset 0px 0px 5px 2px ${Colors.$primaryMediumColor};
  background-color: ${Colors.$primarySuperExtraLightColor};
`;

export const FilterContainerContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .price-pointers {
    display: block;
    width: 25px;
    height: 15px;
    cursor: pointer;
    border-radius: 12px;
    background-color: #fff;
    border: 2px solid ${Colors.$primaryLightColor};
    transition: ${Transitions.$linearSmooth};

    &:active {
      top: -1px;
      transform: translateX(-1px);
      width: 27px;
      height: 17px;
    }
  }

  .price-cloud {
    top: 50%;
    transform: translateY(-50%);
    outline: none;
  }

  .price-cloud-number {
    position: absolute;
    top: -30px;
    left: 50%;
    color: #fff;
    padding: 0 7px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 50px 50px;
    background-color: #272a2f;
    transform: translateX(-50%);

    &:before {
      content: "";
      position: absolute;
      top: 18px;
      left: 50%;
      transform: translateX(-50%);
      border-top: 5px solid #272a2f;
      border-right: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid transparent;
    }
  }

  .price-slider {
    position: absolute !important;
    width: calc(100% - 14px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .price-track {
    height: 5px;
    top: 50%;
    transform: translateY(-50%);
    // background-color: #ddd;
    background-color: #fff;
  }

  .price-track-1 {
    background-color: ${Colors.$primaryColor};
  }
`;

export const PriceTrack = styled.div`
  top: 0;
  bottom: 0;
  border-radius: 999px;
`;

export const ColorCircleContainer = styled.div`
  width: 80%;
  margin-left: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
`;

export const ColorCircle = styled.label`
  width: 100%;
  display: inline-block;
  width: 15px;
  height: 10px;
  border-radius: 50%;
  border: 0px solid #ccc;
  cursor: pointer;
  margin-top: 7px;
  margin-bottom: 4px;
  margin-right: 16px;

  &.blue {
    background-color: #62cdff;
  }

  &.orange {
    background-color: #fd841f;
  }

  &.cyan {
    background-color: #64c9cf;
  }
  &.orange-light {
    background-color: #ffb740;
  }
  &.green-light {
    background-color: #ade498;
  }
  &.purple-light {
    background-color: #b2a4ff;
  }
  &.red-light {
    background-color: #fe8e9c;
  }
  &.red-purple-light {
    background-color: #fcbcd9;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  margin-left: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
`;

export const CheckboxLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  width: 75%;
`;

export const CheckboxInput = styled.input`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const ProductsLayout = styled.div`
  position: relative;
  margin-bottom: 120px;
  width: 75%;
  ${(props) => props.activeFilters === false && "width: 100% !important"};

  @media only screen and (max-width: ${Breakpoints.$large}) {
    width: 70%;
    margin-bottom: 100px;
  }

  @media only screen and (max-width: ${Breakpoints.$large}) {
    margin-bottom: 80px;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

export const FilterSubmitBtn = styled.button`
  font-size: 12px;
  cursor: pointer;
  letter-spacing: 1px;
  padding: 12px 24px;
  border: 1px solid rgb(120, 120, 120);
  background: transparent;
  transition: 0.3s ${Transitions.smooth};
  font-weight: 600;

  &:hover {
    color: #fff;
    background-color: ${Colors.$primaryColor};
    border: 1px solid ${Colors.$primaryColor};
  }
`;

export const ActiveSortButton = styled.div`
  display: none;
  align-items: center;
  padding: 2px 8px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid ${Colors.$primaryMediumColor};
  transition: 0.2s ${Transitions.$linearSmooth};
  user-select: none;

  svg {
    width: 29px;
    height: 23px;

    path {
      stroke: ${Colors.$primaryMediumColor};
    }
  }

  p {
    user-select: none;
    letter-spacing: 0.5px;
    color: ${Colors.$primaryMediumColor};
  }

  &:active {
    transform: scale(0.95);
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    display: flex;
  }
`;

export const FilterTotal = styled.p`
  position: relative;
  line-height: 1;
  font-size: 18px;
  padding-left: 13px;
  font-weight: normal;

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 7px;
    left: 0;
    border-radius: 3px;
    background-color: ${Colors.$primaryColor};
  }
`;

export const FilterTitle = styled.p`
  font-size: 18px;
  color: #b2b7bc;
  position: relative;
  font-weight: 100;
  padding-bottom: 5px;
  line-height: 1;
  padding-bottom: 15px;
`;

export const MobileDropdown = styled.div`
  display: none;

  @media (max-width: ${Breakpoints.$small}) {
    display: block;
  }
  .Dropdown-control,
  .Dropdown-menu {
    border: 1px solid ${Colors.$primaryMediumColor};
  }

  .Dropdown-menu {
    border-top: none;
  }
`;
