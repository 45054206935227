import React, { useState, useEffect } from "react";
import { Flex, Row } from "../../layouts";
import Pagination from "../pagination";
import {
  FiltersContainer,
  FilterForm,
  FilterContainer,
  FilterContainerContent,
  FilterSubmitBtn,
  FilterTotal,
  FilterTitle,
  PriceTrack,
  ReactSliderContainer,
  ReactSliderBackground,
  ProductsLayout,
  ActiveSortButton,
  FilterMobileBackground,
  MobileDropdown,
} from "./layouts";

import Product from "../product";
import CustomCheckbox from "../custom-checkbox";
import ReactSlider from "react-slider";
import Icon from "../../assets/icons/icon";
import Dropdown from "react-dropdown";
import { dropdownOptions } from "./variables";
import "react-dropdown/style.css";

const Products = ({
  data,
  container,
  sortParam,
  categories,
  minMaxPrice,
  activeFilters,
  paginateNumer,
  priceValuesParam,
  selectedCategoriesParam,
}) => {
  const [sort, setSort] = useState(
    sortParam
      ? dropdownOptions.find((elem) => elem.foreignKey === sortParam)
      : null
  );
  const [selectedCategories, setSelectedCategories] = useState(
    selectedCategoriesParam ? selectedCategoriesParam : []
  );
  const [currentPage] = useState(
    parseInt(paginateNumer, 10) ? parseInt(paginateNumer, 10) : 1
  );
  const [postsPerPage] = useState(12);
  const [mobileFiltersStatus, toggleMobileFilterStatus] = useState(false);
  const [allProducts, setAllProducts] = useState(null);
  const [priceValues, setPriceValues] = useState(priceValuesParam);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  useEffect(() => {
    if (data) {
      const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);
      setAllProducts(currentPosts);

      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [data, currentPage]);

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    if (selectedCategories.includes(value)) {
      setSelectedCategories(
        selectedCategories.filter((category) => category !== value)
      );
    } else {
      setSelectedCategories([...selectedCategories, value]);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    filterPage({ pageNumber: 1 });
  };

  const paginate = (pageNumber) => {
    filterPage({ pageNumber: pageNumber });
  };

  const mobileSort = (e) => {
    filterPage({ mobileSortValue: e });
  };

  function filterPage({ pageNumber = null, mobileSortValue = null }) {
    const queryParams = new URLSearchParams();

    if (pageNumber !== null) {
      queryParams.append("page", pageNumber);
    }

    if (mobileSortValue || (sort && sort.value)) {
      const sortVal = mobileSortValue ? mobileSortValue.value : sort.value;
      queryParams.append("sort", sortVal);
    }

    if (selectedCategories.length > 0) {
      queryParams.append("categories", selectedCategories.join(","));
    }

    if (priceValues.minPrice && priceValues.maxPrice) {
      queryParams.append("minPrice", priceValues.minPrice);
      queryParams.append("maxPrice", priceValues.maxPrice);
    }

    const queryString = queryParams.toString();
    window.location.search = queryString;
  }

  if (!allProducts) return;

  return (
    <div className={container ? "container" : ""}>
      {activeFilters && (
        <Flex className="mb-20 mr-15 ml-15 ml-sm-8 mr-sm-8 justify-between mobile-dropdown">
          <MobileDropdown>
            <Dropdown
              name="sorting"
              options={dropdownOptions}
              value={sort}
              onChange={(e) => mobileSort(e)}
              placeholder="Radhit produktet"
            />
          </MobileDropdown>

          <ActiveSortButton
            onClick={() => toggleMobileFilterStatus(!mobileFiltersStatus)}
          >
            <Icon icon="filter" />
            <p>Filtro</p>
          </ActiveSortButton>
        </Flex>
      )}
      <Flex>
        {activeFilters && (
          <>
            <FiltersContainer className={mobileFiltersStatus ? "active" : ""}>
              <FilterForm onSubmit={handleSubmit}>
                {data && (
                  <>
                    <FilterContainer>
                      <FilterTotal>
                        <strong>{data.length}</strong> produkte të lira
                      </FilterTotal>
                    </FilterContainer>
                  </>
                )}
                <FilterContainer>
                  <Dropdown
                    name="sorting"
                    options={dropdownOptions}
                    value={sort}
                    onChange={(e) => setSort(e)}
                    placeholder="Radhit produktet"
                  />
                </FilterContainer>
                {categories && (
                  <>
                    <FilterContainer>
                      <FilterContainerContent>
                        <FilterTitle>Sipas kategorise</FilterTitle>
                        {categories.map((category, index) => {
                          const { slug, title } = category;

                          if (slug === "dollap") {
                            return (
                              <CustomCheckbox
                                key={index}
                                id={slug}
                                name={slug}
                                value={slug}
                                label={title}
                              />
                            );
                          } else {
                            return (
                              <CustomCheckbox
                                key={index}
                                id={slug}
                                name={slug}
                                value={slug}
                                label={title}
                                checked={selectedCategories.includes(slug)}
                                onChange={handleCheckboxChange}
                              />
                            );
                          }
                        })}
                      </FilterContainerContent>
                    </FilterContainer>
                  </>
                )}
                <FilterContainer>
                  <FilterTitle className="mb-30">Sipas çmimit</FilterTitle>
                  <FilterContainerContent>
                    <ReactSliderContainer>
                      <ReactSliderBackground>
                        {minMaxPrice && (
                          <ReactSlider
                            className="price-slider"
                            thumbClassName="price-cloud"
                            trackClassName="price-track"
                            defaultValue={
                              priceValues?.maxPrice && priceValues?.minPrice
                                ? [priceValues.minPrice, priceValues.maxPrice]
                                : [
                                    minMaxPrice?.minPrice
                                      ? minMaxPrice.minPrice
                                      : 0,
                                    minMaxPrice?.maxPrice
                                      ? minMaxPrice.maxPrice
                                      : 2000,
                                  ]
                            }
                            max={
                              minMaxPrice?.maxPrice
                                ? minMaxPrice.maxPrice
                                : 2000
                            }
                            min={
                              minMaxPrice?.minPrice ? minMaxPrice.minPrice : 0
                            }
                            name="price"
                            minDistance={20}
                            pearling
                            withTracks
                            renderTrack={(props, state) => (
                              <PriceTrack {...props} index={state.index} />
                            )}
                            onAfterChange={(result) => {
                              setPriceValues({
                                minPrice: result[0],
                                maxPrice: result[1],
                              });
                            }}
                            renderThumb={(props, state) => (
                              <div {...props}>
                                <p className="price-cloud-number">
                                  {state.valueNow}€
                                </p>
                                <span className="price-pointers" />
                              </div>
                            )}
                          />
                        )}
                      </ReactSliderBackground>
                    </ReactSliderContainer>
                  </FilterContainerContent>
                </FilterContainer>
                <FilterContainer>
                  <FilterSubmitBtn type="submit">Filtro</FilterSubmitBtn>
                </FilterContainer>
              </FilterForm>
            </FiltersContainer>
            <FilterMobileBackground
              className={mobileFiltersStatus ? "active" : ""}
              onClick={() => toggleMobileFilterStatus(false)}
            />
          </>
        )}
        <ProductsLayout activeFilters={activeFilters}>
          <Row className="w-full no-margin pb-30">
            {allProducts.map((product, index) => {
              return (
                <Product
                  key={index}
                  size="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3"
                  data={product}
                />
              );
            })}
          </Row>
          <Flex className="justify-center">
            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={data.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </Flex>
        </ProductsLayout>
      </Flex>
    </div>
  );
};

export default Products;
