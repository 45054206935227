import styled from "@emotion/styled";
import {
  Breakpoints,
  Colors,
  Transitions,
} from "../../assets/variables/variables";

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 2px solid ${Colors.$colorGreyLight};
  border-radius: 6px 6px 0 0;
  border-bottom: none;
`;

export const Tab = styled.div`
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  padding: 10px 25px;
  position: relative;

  &:before {
    content: "";
    width: 0;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${Colors.$primaryColor};
    transition: 0.3s ${Transitions.$superSmooth};
  }

  &:first-of-type {
    &:before {
      left: initial;
      right: 0;
    }
  }

  &.active {
    color: ${Colors.$primaryColor};

    &:before {
      width: 100%;
    }
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    padding: 12px 15px;
  }
`;
