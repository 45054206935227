import Markdown from "react-markdown";

const RenderText = (text) => {
  const paragraphs = text
    .split("\n")
    .map((paragraph, index) => <Markdown key={index}>{paragraph}</Markdown>);

  return paragraphs;
};

export default RenderText;
