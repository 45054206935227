import React from "react";
import Hero from "../hero";
import OurCategories from "../our-categories";
import MixedCategories from "../mixed-categories";
import OurApproach from "../our-approach";
import OurCompany from "../our-company";
import OurSlider from "../our-slider";
import { Container } from "../../layouts";

const listOfComponents = {
  post: Hero,
  ourSlider: OurSlider,
  ourCompany: OurCompany,
  ourApproach: OurApproach,
  ourCategories: OurCategories,
  ourMixedCategories: MixedCategories,
};

const DynamicComponent = ({ listOfComponentsType }) => {
  const renderContent = (container, type, data, index) => {
    const Component = listOfComponents[type];

    if (Component) {
      const componentElement = <Component key={index} {...data} />;

      if (container) {
        return <Container>{componentElement}</Container>;
      }

      return componentElement;
    }

    return;
  };

  return (
    <>
      {listOfComponentsType.map((componentData, index) => {
        const componentType = componentData.fields.type;
        const componentContainer = componentData.fields.container;

        return (
          <React.Fragment key={index}>
            {renderContent(
              componentContainer,
              componentType,
              componentData,
              index
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default DynamicComponent;
