import styled from "@emotion/styled";
import {
  Breakpoints,
  Colors,
  Transitions,
} from "../../assets/variables/variables";
import { Link } from "react-router-dom";

export const MixedCategoriesContainer = styled.div`
  padding: 70px 0 100px 0;
  display: block;
  background-color: ${Colors.$colorBackgroundSection};

  @media only screen and (max-width: ${Breakpoints.$large}) {
    padding: 50px 0;
  }
`;

export const MixedCategoriesContent = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${Breakpoints.$large}) {
    flex-wrap: wrap;
  }
`;

export const Category = styled(Link)`
  position: relative;
  width: calc(33% - 15px);
  aspect-ratio: 1/1.09;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    .cat-description {
      bottom: 0;
      opacity: 1;
      width: calc(85% - 20px);

      @media only screen and (max-width: ${Breakpoints.$large}) {
        width: 85%;
      }
    }
  }

  @media only screen and (max-width: ${Breakpoints.$large}) {
    width: calc(50% - 15px);
    aspect-ratio: 1/0.7;
    margin-bottom: 50px;
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    width: 100%;
    aspect-ratio: 1/0.4;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    width: 100%;
    aspect-ratio: 1/0.65;
  }
`;

export const CategoryTitle = styled.h3`
  position: absolute;
  font-size: 18px;
  font-weight: 500;
  bottom: -25px;
  right: -20px;
  width: 85%;
  text-align: center;
  padding: 15px 20px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: -1.216px 6.894px 25px rgba(0, 0, 0, 7%);

  @media only screen and (max-width: ${Breakpoints.$large}) {
    right: 0;
  }
`;

export const CategoryDescription = styled.div`
  position: absolute;
  bottom: 15px;
  right: 0;
  opacity: 0;
  background: #fff;
  border-radius: 12px;
  width: calc(85% - 20px);
  padding: 15px 15px 40px 15px;
  transition: 0.4s ${Transitions.$superSmooth};
  user-select: none;

  @media only screen and (max-width: ${Breakpoints.$large}) {
    width: 85%;
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    opacity: 1;
    bottom: 0;
  }
`;
