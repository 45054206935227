import { Container } from "../../layouts";
import { LoaderLayout } from "./layouts";

const Loader = () => {
  return (
    <LoaderLayout>
      <Container className="d-flex justify-center h-full">
        <div className="loader" />
      </Container>
    </LoaderLayout>
  );
};

export default Loader;
