import styled from "@emotion/styled";
import {
  Breakpoints,
  Colors,
  Transitions,
} from "../../assets/variables/variables";
import { Link } from "react-router-dom";

export const ImagesContent = styled.div`
  display: flex;
  width: 60%;

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    width: 100%;
    padding-bottom: 20px;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    flex-direction: column-reverse;
    padding-bottom: 30px;
  }
`;

export const FeaturedContainer = styled.div`
  position: relative;
  padding-right: 10px;
  height: 500px;

  @media only screen and (max-width: ${Breakpoints.$small}) {
    height: initial;
    width: 100%;
    padding-right: 0;
  }
`;

export const SingleProductImage = styled.div`
  position: relative;
  display: flex;
  height: 500px;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: calc(100% - 110px);
  background-color: #f2f2f2;

  img {
    width: 100%;
    height: 100%;
  }

  ${(props) => props.outOfStock && '&:before {content: ""}'};

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.2;
    background-color: #000;
    width: 100%;
    height: 100%;
    z-index: 9;
  }

  p {
    font-size: 25px;
    position: absolute;
    left: 50%;
    top: 50%;
    color: #000;
    background-color: #fff;
    padding: 5px 30px;
    white-space: nowrap;
    border-radius: 6px;
    z-index: 10;
    opacity: 0.9;
    transform: translate(-50%, -50%) rotate(-35deg);
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    width: 100%;
    aspect-ratio: 1/1;
    height: initial;
    max-height: 450px;
  }
`;

export const FeaturedImages = styled.div`
  width: 100px;
  position: relative;
  margin-right: 10px;
  display: inline-block;
  overflow-y: auto;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  height: 500px;
  margin: 0 auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    display: flex;
    width: 100%;
    height: initial;
    overflow-y: visible;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }
`;

export const FeaturedImage = styled.div`
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  position: relative;
  // background-color: #f7f9fb;
  background-color: #f2f2f2;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  &:last-of-type {
    margin-bottom: 30px;
  }

  &.active {
    border: 2px solid ${Colors.$primaryColor};
    border-radius: 4px;
  }

  ${(props) => props.outOfStock && '&:before {content: ""}'};

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.2;
    background-color: #000;
    width: 100%;
    height: 100%;
    z-index: 9;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    flex: 0 0 100px;
    margin-bottom: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const MixedCategoryTitle = styled.div`
  position: relative;
  display: inline-block;
  padding: 3px 10px;
  border-radius: 6px;
  background-color: ${Colors.$primarySuperLightColor};
  background-color: #e6f1fc;
  color: ${Colors.$primaryMediumColor};
  font-size: 14px;
  font-weight: 100;
  margin-right: 7px;
`;

export const CategoriesTitle = styled(Link)`
  display: inline-block;
  padding: 0 3px;
  padding-bottom: 5px;
  font-weight: 100;
  font-size: 14px;
  font-style: italic;
  color: #000;
  opacity: 0.7;
  transition: 0.2s ${Transitions.$linearSmooth};

  &:hover {
    opacity: 1;
    color: ${Colors.$primaryMediumColor};
  }
`;

export const Price = styled.p`
  display: inline-block;
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
  color: ${Colors.$primaryColor};
`;

export const SalePrice = styled.p`
  font-size: 28px;
  font-weight: 100;
  opacity: 0.7;
  line-height: 1;
  padding-right: 5px;
  text-decoration: line-through;
`;

export const BuyButton = styled.div`
  padding: 8px 20px;
  outline: none;
  border: none;
  ${(props) => (props.type === "buy" ? "padding: 8px 35px;" : "width: 170px")};
  display: flex;
  align-items: center;
  position: relative;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 300;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: ${(props) =>
    props.color ? props.color : Colors.$colorBuyBtn};

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    box-shadow: 4px 4px 9px 0px
      ${(props) => (props.color ? props.color : Colors.$colorBuyBtn)};
    opacity: 0.6;
    z-index: -1;
    transition: 0.2s ${Transitions.$linearSmooth};
  }

  svg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const BuyParagraph = styled.p`
  margin: 10px 0;
  font-size: 16px;
  color: #000;
  opacity: 0.8;
`;

export const BuyOpptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  opacity: 0;
  transform: translateY(-30px);
  transition: 0.3s ${Transitions.$superSmooth};
  visibility: hidden;

  &.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
`;

export const FeaturedButton = styled.button`
  outline: none;
  width: 100%;
  position: absolute;
  background: transparent;
  border: none;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7455357142857143) 38%,
    rgba(255, 255, 255, 1) 85%
  );

  svg {
    width: 25px;
    height: 25px;
  }

  &:last-of-type {
    top: initial;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.7455357142857143) 38%,
      rgba(255, 255, 255, 1) 85%
    );

    svg {
      transform: rotate(180deg);
    }
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    width: 40px;
    width: 30px;
    height: 30px;
    top: 50% !important;
    left: 5px;
    padding: 0;
    transform: translateY(-50%);
    border-radius: 100%;
    background: #ffffffba !important;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transform: rotate(-90deg);
    }

    &:last-of-type {
      left: initial;
      right: 5px;

      svg {
        transform: rotate(90deg);
      }
    }
  }
`;
