import styled from "@emotion/styled";
import {
  Breakpoints,
  Colors,
  Transitions,
} from "../../assets/variables/variables";
import { css } from "@emotion/react";

export const SliderContainer = styled.div`
  position: relative;
  padding-bottom: 50px;

  img {
    width: 100%;
  }
`;

export const SliderMain = styled.div`
  height: 600px;
  width: 100%;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  .slick-list {
    overflow: hidden;
  }

  .slick-track {
    display: flex;
  }

  .slick-slider,
  .slick-list,
  .slick-track {
    user-select: none;
    position: relative;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .slick-slide {
    height: 100%;

    > div {
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }

  @media only screen and (max-width: ${Breakpoints.$large}) {
    height: 500px;
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    height: 450px;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    height: 350px;
  }
`;

export const Thumbnails = styled.div`
  position: relative;
  padding: 30px 0;
  height: 200px;
  width: 100%;
  margin-top: 30px;
  padding: 0 15%;

  img {
    height: 100%;
  }

  hr {
    display: none;
  }

  .slick-slide {
    width: 230px;
    flex: 0 0 230px;
    height: 160px;
    padding: 0 15px;
    transition: 0.4s ${Transitions.$linearSmooth};

    &.slick-current {
      height: 200px;

      img {
        border: 1px solid ${Colors.$primaryColor};
      }
    }

    @media only screen and (max-width: ${Breakpoints.$large}) {
      height: 140px;
      width: 200px;
      flex: 0 0 200px;
      padding: 0 10px;

      &.slick-current {
        height: 180px;
      }
    }

    @media only screen and (max-width: ${Breakpoints.$medium}) {
      height: 110px;
      width: 180px;
      flex: 0 0 180px;

      &.slick-current {
        height: 150px;
      }
    }
  }

  .slick-list {
    overflow: hidden;
  }

  .slick-slider,
  .slick-list {
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .slick-track {
    display: flex;
    align-items: center;
    height: 100%;

    .slick-slide {
      > div {
        height: 100%;

        > div {
          height: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: ${Breakpoints.$large}) {
    height: 180px;
    padding: 0 10%;
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    height: 150px;
    padding: 0 5%;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    padding: 0;
  }
`;

export const ArrowSlider = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  transition: 0.2s ${Transitions.$linearSmooth};
  user-select: none;
  
  &:hover {
    background-color: #fff;
  }

  &:last-of-type {
    left: initial;
    right: 15px;
  }

  ${(props) =>
    props.type === "main"
      ? css`
          background-color: rgba(255, 255, 255, 0.7);
        `
      : css`
          width: 40px;
          height: 40px;
          background-color: rgba(0, 0, 0, 0.7);
          left: calc(15% - 65px);

          &:last-of-type {
            right: calc(15% - 65px);
          }

          svg {
            width: 13px;
            height: 13px;

            path {
              fill: #fff;
            }
          }

          &:hover {
            background-color: #000;
          }

          @media only screen and (max-width: ${Breakpoints.$large}) {
            left: calc(10% - 65px);

            &:last-of-type {
              right: calc(10% - 65px);
            }
          }

          @media only screen and (max-width: ${Breakpoints.$medium}) {
            display: none;
          }
        `}
`;

export const SliderModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgb(0 0 0 / 80%);

  img {
    margin: 30px;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    object-fit: contain;
    user-select: none;
  }

  .close-btn {
    position: absolute;
    top: 15px;
    right: 15px;

    &:before,
    &:after {
      background-color: #fff;
    }
  }
`;

export const ImageCount = styled.div`
  display: flex;
  position: absolute;
  left: 10px;
  top: 10px;

  span {
    display: inline-block;
    padding: 0 5px;
  }

  p,
  span {
    font-size: 18px;
    color: #fff;

    @media only screen and (max-width: ${Breakpoints.$medium}) {
      font-size: 16px;
    }
  }
`;

export const SliderTip = styled.span`
  font-size: 12px;
  font-family: "Raleway-Light";
  font-style: italic;
  letter-spacing: 1px;
  margin-top: 3px;
  position: relative;
  display: inline-block;
`;
