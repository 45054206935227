import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/scss/style.scss";
import Home from "./pages/home";
import About from "./pages/about";
import ErrorPage from "./pages/error";
import Header from "./components/header";
import Footer from "./components/footer";
import SingleProduct from "./pages/singleproducts";
import Products from "./pages/products";
// import Comingsoonpage from "./pages/comingsoonpage";
import Contact from "./pages/contact";
import Search from "./pages/searchPage";
import { ExtrasDataProvider } from "./Contexts/ExtrasContext";
import { AnalyticsWrapper } from "./components/Analytics-Wrapper";

const App = () => {
  // const [showWelcome, setShowWelcome] = useState(false);
  const [isHomepage, setIsHomepage] = useState(false);

  // useEffect(() => {
  // const savedState = localStorage.getItem("showWelcome");
  // if (savedState === "false") {
  //   setShowWelcome(false);
  // }

  // const handleKeyDown = (event) => {
  //   if (event.altKey && event.key === "*") {
  //     setShowWelcome(false);
  //     localStorage.setItem("showWelcome", "false");
  //   }
  // };

  // const handleTouchStart = () => {
  //   let timeoutId;

  //   const handleTouchEnd = () => {
  //     clearTimeout(timeoutId);
  //   };

  //   timeoutId = setTimeout(() => {
  //     setShowWelcome(false);
  //     localStorage.setItem("showWelcome", "false");
  //   }, 10000);

  //   document.addEventListener("touchend", handleTouchEnd);

  //   return () => {
  //     clearTimeout(timeoutId);
  //     document.removeEventListener("touchend", handleTouchEnd);
  //   };
  // };

  //   document.addEventListener("keydown", handleKeyDown);
  //   document.addEventListener("touchstart", handleTouchStart);

  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //     document.removeEventListener("touchstart", handleTouchStart);
  //   };
  // }, []);

  return (
    <BrowserRouter>
      <ExtrasDataProvider>
        <AnalyticsWrapper>
          <div
            className={`p-relative error-page ${isHomepage ? "homepage" : ""}`}
          >
            <Header setIsHomepage={setIsHomepage} />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/search" element={<Search />} />
              <Route path="/products/:slug" element={<SingleProduct />} />
              <Route exact strict path="/products" element={<Products />} />
              <Route exact strict path="/contact" element={<Contact />} />
              <Route path="/*" element={<ErrorPage />} />
            </Routes>
            <Footer />
          </div>
        </AnalyticsWrapper>
      </ExtrasDataProvider>
    </BrowserRouter>
  );
};

export default App;
