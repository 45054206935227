import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { useEffect } from "react";
ReactGA.initialize(process.env.REACT_APP_ANALYTICS);

export const AnalyticsWrapper = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return <>{children}</>;
};
