import Image from "../image";
import { Flex } from "../../layouts";
import RenderText from "../render-text";
import Icon from "../../assets/icons/icon";
import { BigTitle, Content, Paragraph, Title } from "../../layouts/layouts";
import {
  Block,
  BlockImage,
  BlockTitle,
  BlockContent,
  CompanyImage,
  GeneralBlock,
  CompanyHead,
  GeneralCompanyImage,
  GeneralBlockContent,
} from "./layouts";

const OurCompany = (data) => {
  const {
    title = "",
    image = "",
    description = "",
    companyLocation = "",
    companyLocationTitle = "",
    companyProductsImage = "",
    companyProductsTitle = "",
    companyGeneralInformation = "",
    companyGeneralInformationImage = "",
    companyGeneralInformationTitle = "",
    companyExperienceDescription = "",
    companyExperienceImage = null,
    companyExperienceTitle = "",
  } = data.fields;

  return (
    <div className="p-relative">
      <CompanyHead>
        <BigTitle>{title}</BigTitle>
        <Paragraph>{description}</Paragraph>
      </CompanyHead>
      <Flex>
        {companyExperienceImage ||
          companyExperienceDescription ||
          (companyExperienceTitle && (
            <Block>
              {companyExperienceImage && (
                <BlockImage render={1}>
                  <Image source={companyExperienceImage.fields.file.url} />
                </BlockImage>
              )}
              {companyExperienceDescription && (
                <BlockContent animate={true} render={1}>
                  {companyExperienceDescription}
                </BlockContent>
              )}
              {companyExperienceTitle && (
                <BlockTitle>
                  <Icon icon="quotes" />
                  {companyExperienceTitle &&
                    companyExperienceTitle.map((title, index) => {
                      return <Title key={index}>{title}</Title>;
                    })}
                </BlockTitle>
              )}
            </Block>
          ))}
        {companyProductsTitle ||
          companyProductsImage ||
          (companyProductsTitle && (
            <Block>
              {companyProductsTitle && (
                <BlockTitle animate={true} render={2}>
                  <Icon icon="quotes" />
                  {companyProductsTitle &&
                    companyProductsTitle.map((title, index) => {
                      return <Title key={index}>{title}</Title>;
                    })}
                </BlockTitle>
              )}
              {companyProductsImage && (
                <BlockImage render={2}>
                  <Image source={companyProductsImage.fields.file.url} />
                </BlockImage>
              )}
              {companyProductsTitle && (
                <BlockContent reverse={true}>
                  <Icon icon="quotes" />
                  {companyProductsTitle &&
                    companyProductsTitle.map((title, index) => {
                      return <Title key={index}>{title}</Title>;
                    })}
                </BlockContent>
              )}
            </Block>
          ))}
      </Flex>
      <Flex>
        <div className="w-half">
          {image && (
            <CompanyImage>
              <Image source={image.fields.file.url} />
            </CompanyImage>
          )}
          {companyLocationTitle && companyLocation && (
            <Block>
              <BlockContent solid={true}>
                <Icon icon="locationMap" />
              </BlockContent>
              <BlockTitle solid={true}>
                <a target="_blank" rel="noreferrer" href={companyLocation}>
                  {""}
                </a>
                <Icon icon="location" />
                <Title>{companyLocationTitle}</Title>
              </BlockTitle>
            </Block>
          )}
        </div>
        {companyGeneralInformationTitle ||
          companyGeneralInformation ||
          (companyGeneralInformationImage && (
            <div className="w-half p-relative">
              {companyGeneralInformationTitle && (
                <GeneralBlock animate={true}>
                  <Icon icon="cup" />
                  <Title>{companyGeneralInformationTitle}</Title>
                </GeneralBlock>
              )}
              {companyGeneralInformation && (
                <GeneralBlockContent animate={true}>
                  <Content>{RenderText(companyGeneralInformation)}</Content>
                </GeneralBlockContent>
              )}
              {companyGeneralInformationImage && (
                <GeneralCompanyImage>
                  <Image
                    source={companyGeneralInformationImage.fields.file.url}
                  />
                </GeneralCompanyImage>
              )}
            </div>
          ))}
      </Flex>
    </div>
  );
};

export default OurCompany;
