import React from "react";
import Logo from "../logo";
import { Link } from "react-router-dom";
import { Container, Flex } from "../../layouts";
import { FooterContainer, Paragraph } from "../../layouts/layouts";
import Icon from "../../assets/icons/icon";
import { scrollToTop } from "../../config/functions";

import {
  EmailIcon,
  FacebookIcon,
  FooterLink,
  FooterLinkLine,
  FooterLinks,
  FooterSeperator,
  InstagramIcon,
  LogoContainer,
  ScrollTopArrow,
} from "./layouts";

const Footer = () => {
  return (
    <FooterContainer className="flex w-full color-white">
      <ScrollTopArrow onClick={scrollToTop}>
        <Icon icon="arrowTop" />
      </ScrollTopArrow>
      <Container>
        <div className="d-flex py-50 py-md-30">
          <LogoContainer className="flex-40 pr-15 d-flex flex-column">
            <Logo className="mb-40" invert={true} />
            <Paragraph className="d-inline-block color-white">
              Copyright © 2018-2024
            </Paragraph>
            <span className="d-inline-block color-white">
              BS Trade, Të gjitha të drejtat e rezervuara
            </span>
          </LogoContainer>
          <FooterLinks>
            <div className="p-relative mb-10 mt-30 mt-sm-10">
              <FooterLinkLine>
                <FooterLink>
                  <Link
                    to="/"
                    className="mb-10 text-decoration-none color-white"
                  >
                    Ballina
                  </Link>
                </FooterLink>
                <FooterLink>
                  <Link
                    to="/products"
                    className="mb-10 text-decoration-none color-white"
                  >
                    Produktet
                  </Link>
                </FooterLink>
              </FooterLinkLine>
              <FooterLinkLine>
                <FooterLink>
                  <Link
                    to="/about"
                    className="mb-10 text-decoration-none color-white"
                  >
                    Rreth nesh
                  </Link>
                </FooterLink>
                <FooterLink>
                  <Link
                    to="contact"
                    className="mb-10 text-decoration-none color-white"
                  >
                    Kontakti
                  </Link>
                </FooterLink>
              </FooterLinkLine>
            </div>
            <FooterSeperator />
            <Flex className="mt-30 mt-sm-20 justify-end">
              <a
                href="https://www.facebook.com/profile.php?id=100086357721615"
                target="_blank"
                rel="noreferrer"
                className="mr-15"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://www.instagram.com/_bstrade/"
                target="_blank"
                rel="noreferrer"
                className="mr-15"
              >
                <InstagramIcon />
              </a>
              <a href="mailto:bs-trade@hotmail.com">
                <EmailIcon />
              </a>
            </Flex>
          </FooterLinks>
        </div>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
