import styled from "@emotion/styled";
import { Breakpoints, Colors } from "../../assets/variables/variables.js";

export const Card = styled.div`
  position: relative;
  display: flex;
  width: 25%;
  padding-left: 15px;

  &:first-of-type {
    padding-left: 0;
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    width: calc(50% - 1px);
    margin-bottom: 50px;
    flex-direction: column;
    flex-wrap: wrap;

    + .seperator {
      display: none;
    }

    &:nth-of-type(3) {
      padding-left: 0;
    }
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 20px;
    padding-left: 0;
    flex-direction: row;
    border-bottom: 1px solid ${Colors.$colorGreyStandart};

    + .seperator {
      display: none;
    }

    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
    }
  }
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 0 82%;
  padding: 0 10px;

  p {
    color: #000;
    text-align: center;
    font-weight: 100;
    line-height: 1.3;
  }

  h4 {
    color: ${Colors.$primaryColor};
    text-align: center;
    font-size: 16px;
    line-height: 1;
    font-weight: 900;
    letter-spacing: 2.5px;
    padding-bottom: 15px;
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    padding: 0;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    flex: 0 calc(100% - 60px);
  }
`;

export const CardImageHeader = styled.div`
  position: relative;
  display: flex;
  flex: 0 17%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    flex: 0 100%;
    width: 100%;
    justify-content: center;
    padding-bottom: 20px;

    img {
      position: relative;
      width: 60px;
    }
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    flex: 0 60px;
    padding-bottom: 0;
  }
`;

export const Seperator = styled.span`
  width: 1px;
  height: 50px;
  background-color: #d3d2d2;
`;
