import styled from "@emotion/styled";
import { Breakpoints, Colors } from "../assets/variables/variables";

export const FooterContainer = styled.div`
  position: relative;
  background-color: ${Colors.$primaryColor};
`;

export const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 30px 0px;
`;

export const LogoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 0 120px;
  width: 120px;
  height: 120px;
  margin-right: 20px;

  .invert {
    filter: invert(100%);
    -webkit-filter: invert(100%);
  }

  ${(props) => (props.headerStatus === true ? "z-index: 101" : "")};

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    flex: 0 0 80px;
    width: 80px;
    height: 80px;
    margin-right: 50px;
  }
`;

export const BigTag = styled.span`
  position: relative;
  font-size: 20px;
  line-height: 1.1;
  color: ${Colors.$tagColor};
`;

export const Tag = styled.span`
  position: relative;
  font-size: 16px;
  line-height: 1.1;
  color: ${(props) => (props.color ? props.color : Colors.$tagColor)};
`;

export const BigTitle = styled.h1`
  font-size: 54px;
  line-height: 1.3;

  @media only screen and (max-width: ${Breakpoints.$large}) {
    font-size: 46px;
  }

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    font-size: 38px;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    font-size: 30px;
  }
`;

export const Title = styled.h2`
  font-size: 32px;

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    font-size: 24px;
  }
`;

export const SubTitle = styled.h2`
  font-size: 25px;
  ${(props) => (props.color ? `color: ${props.color}` : "")};

  @media only screen and (max-width: ${Breakpoints.$medium}) {
    font-size: 22px;
  }
`;

export const Paragraph = styled.p`
  font-size: 18px;

  @media only screen and (max-width: ${Breakpoints.$small}) {
    font-size: 16px;
  }
`;

export const SmallParagraph = styled.p`
  font-size: 14px;
  font-weight: 100;
`;

export const ProductTitle = styled.h3`
  display: inline-block;
  font-size: 16px;
  // color: #7c7c7c;
  color: #191919;
  padding: 0;
  line-height: 1;
  font-weight: normal;
  padding-bottom: 5px;
`;

export const ProductPrice = styled.div`
  p {
    display: inline-block;
    position: relative;
    font-size: 16px;
    color: #f5484a;
    padding-right: 5px;
  }

  span {
    display: inline-block;
    position: relative;
    font-size: 14px;
    text-decoration: line-through;
    color: #334155;
    opacity: 0.8;
  }
`;

export const Quote = styled.span`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px;
  font-style: italic;
  line-height: 1;

  &:before {
    position: absolute;
    content: "";
    width: 2px;
    height: 100%;
    left: 0;
    top: 0;
    background: ${Colors.$primaryColor};
  }
`;

export const TitleExpresion = styled.h3`
  position: relative;
  padding-left: 50px;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${Colors.$primaryColor};

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 40px;
    height: 2px;
    border-radius: 3px;
    background-color: ${Colors.$primaryColor};
    transform: translateY(-50%);
  }
`;

export const CategoriesLinkEffect = styled.p`
  padding: 5px;
  &:before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${Colors.$primaryColor};
    transition: 0.2s;
  }

  &:hover {
    &:before {
      bottom: 3px;
    }
  }
`;

export const SubmitButton = styled.button`
  font-size: 15px;
  cursor: pointer;
  background-color: #384aeb;
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 15px 40px 15px 40px;

  &:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid ${Colors.$colorGreyStandart};
  }
`;

export const GeneralIcon = styled.span`
  position: relative;
  display: block;
  width: 40px;
  height: 6px;
  border-radius: 20px;
  margin-top: 25px;
  background-color: ${Colors.$primaryLightColor};

  &:before,
  &:after {
    content: "";
    position: absolute;
    height: 6px;
    border-radius: 20px;
    background-color: ${Colors.$primaryLightColor};
  }

  &:before {
    width: 23px;
    left: 5px;
    top: 11px;
  }

  &:after {
    width: 30px;
    right: -10px;
    top: -11px;
  }
`;

export const ProductsPage = styled.div`
  background-color: #f7f9fb;
  padding: 70px 0;

  @media only screen and (max-width: ${Breakpoints.$small}) {
    padding: 20px 0;
  }
`;

export const Seperator = styled.span`
  display: inline-block;
  position: relative;
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: ${Colors.$colorGreyMedium};
  opacity: 0.8;
`;

export const Content = styled.div`
  padding: 30px 25px;
  position: relative;
  border: 2px solid ${Colors.$colorGreyLight};
  border-radius: 0 0 12px 12px;
  border-top: 0;

  p {
    padding-bottom: 15px;
  }

  ul,
  ol {
    margin-left: 25px;
    margin-bottom: 15px;
  }

  a {
    color: ${Colors.$primaryColor};
    text-decoration: underline;
  }

  blockquote {
    margin: 0;
    margin-left: 35px;
  }

  @media only screen and (max-width: ${Breakpoints.$small}) {
    padding: 20px 15px 10px 15px;
  }
`;
