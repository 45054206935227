import { client } from "./client";
import {
  productsQuery,
  singleCategory,
  mixedCategoriesQuery,
  categoryProductsQuery,
  categoriesQuery,
  minmaxpriceQuery,
} from "./queries";

export const getData = (config) => {
  const { page = null, clear = false } = config;

  const defaultConfig = {
    "sys.id": page,
    include: 10,
    limit: 200,
  };

  const entryCall = page
    ? client.getEntries(defaultConfig)
    : client.getEntries(config);

  return new Promise((resolve) => {
    entryCall
      .then((response) => {
        const data = clear
          ? response.items[0].fields
          : page
          ? response.items[0].fields.content
          : response.items[0];
        resolve({ result: data, error: null });
      })
      .catch((error) => {
        resolve({ result: null, error: error });
      });
  });
};

export const getQueryData = (config) => {
  return new Promise((resolve, reject) => {
    const spaceId = "94f3hdo0fe0q";
    const accessToken = "rSXqJta2Xt4DxDwdFZkHHbuBp-Jhraoui20A4-9qYqw";
    const apiUrl = `https://graphql.contentful.com/content/v1/spaces/${spaceId}`;
    const {
      slug,
      sort,
      skip = 1,
      minPrice,
      maxPrice,
      searchTerm,
      categorySlug,
      imagesLimit = 1,
      currentProductSlug,
      itemsLimit = 400,
      type = "products",
    } = config;

    const queryTypes = {
      minmaxprice: { query: minmaxpriceQuery },
      categories: { query: categoriesQuery },
      mixedCategories: { query: mixedCategoriesQuery },
      singleCategory: { query: singleCategory, variables: { categorySlug } },
      products: {
        query: productsQuery,
        variables: {
          slug,
          sort,
          minPrice,
          maxPrice,
          searchTerm,
          itemsLimit,
          imagesLimit,
          categorySlug,
        },
      },
      categoryProducts: {
        query: categoryProductsQuery,
        variables: { categorySlug, itemsLimit, skip, currentProductSlug },
      },
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(queryTypes[type]),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        resolve({ result: data.data, error: null });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const pageIds = {
  homepage: "1ua2C1nliDLShuT7n97pha",
  about: "7zY8yR2e0UOhjM5WffS1Ul",
  contact: "7AWQXb9bKMGaQiAJtTjL47",
  extras: "4DqqSkUyMLGpEOi7CwetQ7",
};
